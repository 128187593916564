import React from "react";

import {CheckboxGroupField} from "@reside/ui";
import {useFormikContext} from "formik";
import {useUpdateFacilityFeatureFlags} from "../../../../page-admin-feature-flags/hooks";
import {useFacilityPccFeatureFlags} from "../../../hooks";
import {useFetchFacilitiesQuery} from "../../../../../routers/AdminAppRefresh";
import {FacilityPccMappingFormValues} from "./types";
import {BackdropSpinner} from "../../../../../atoms/spinner";

type PccFeatureFlagsProps = {
  facilityId: string;
  hasPccMappingData: boolean;
  isPccEnabled: boolean;
  isPccFacilityMappingUpdating: boolean;
};
export const PccFeatureFlags = ({
  facilityId,
  hasPccMappingData,
}: PccFeatureFlagsProps): JSX.Element => {
  const {mutate, isLoading: isUpdatingPccFeatureFlags} =
    useUpdateFacilityFeatureFlags();

  const {refetchActiveFeatureFlags, facilityPccFeatureFlags} =
    useFacilityPccFeatureFlags();

  const {values} = useFormikContext<FacilityPccMappingFormValues>();

  const {fetchFacilitiesQuery, isFetchingFacilities} =
    useFetchFacilitiesQuery();

  return (
    <BackdropSpinner active={isFetchingFacilities || isUpdatingPccFeatureFlags}>
      <CheckboxGroupField
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;

          const updatedFeatureFlags = target.checked
            ? [...values.pccFeatureFlags, target.value] // Add the value if checked
            : values.pccFeatureFlags.filter(item => item !== target.value); // Remove the value if unchecked

          mutate({
            id: facilityId,
            featureTypes: updatedFeatureFlags,
            onSuccess: () => {
              refetchActiveFeatureFlags();
              fetchFacilitiesQuery();
            },
            onSettled: () => {},
          });
        }}
        disabled={!hasPccMappingData || isFetchingFacilities}
        name="pccFeatureFlags"
        options={facilityPccFeatureFlags ?? []}
      />
    </BackdropSpinner>
  );
};
