import React from "react";
import {SelectField, TextField} from "@reside/ui";
import {useFormikContext} from "formik";
import {DocumentUploadCategory} from "@reside/reside-api-admission";

import {useDocumentUploadCategoriesQuery} from "../../../../../models/pcc";
import {useFacilitySettingsParams} from "../../../hooks/useFacilitySettingsParams";
import {FacilityPccMappingFormValues} from "./types";

/**
 * Component that renders the form fields for PointClickCare (PCC) facility settings.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} props.isPccEnabled - Flag indicating if PCC is enabled for the facility.
 * @returns {JSX.Element} JSX element representing the form fields for PCC settings.
 *
 * @see {@link useDocumentUploadCategoriesQuery} for fetching the document upload categories from PCC.
 * @see {@link useFacilitySettingsParams} for retrieving facility ID from the route parameters.
 *
 */
export const FormFields = ({
  isPccEnabled,
}: {
  isPccEnabled: boolean;
}): JSX.Element => {
  const {facilityId} = useFacilitySettingsParams();

  const {values} = useFormikContext<FacilityPccMappingFormValues>();

  const {
    data: documentUploadCategories = [],
    isLoading: isLoadingPccCategories,
  } = useDocumentUploadCategoriesQuery({
    facilityId,
    enabled: isPccEnabled,
  });

  const anyPccChecboxIsChecked = Boolean(values.pccFeatureFlags.length);

  return (
    <>
      <TextField
        label="PointClickCare Organization UUID"
        name="pccOrganizationId"
        disabled={anyPccChecboxIsChecked}
      />
      <TextField
        label="PointClickCare Facility ID"
        name="pccFacilityId"
        format="integer"
        disabled={anyPccChecboxIsChecked}
      />
      {isPccEnabled && (
        <SelectField<DocumentUploadCategory>
          name="defaultDocumentCategoryIdentifier"
          label={
            isLoadingPccCategories
              ? "Loading, PointClickCare Document Categories"
              : "PointClickCare Document Category for final PDFs (Choose from list)"
          }
          disabled={isLoadingPccCategories}
          options={documentUploadCategories}
          getOptionValue={category => category.id}
          getOptionLabel={category => category.name}
        />
      )}
    </>
  );
};
