"use strict";
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Reside Application
 * Reside application backend
 *
 * OpenAPI spec version: 0.1.0
 * Contact: peter.sokolik@thinkcreatix.com
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserControllerApi = exports.UserControllerApiFactory = exports.UserControllerApiFp = exports.UserControllerApiFetchParamCreator = exports.OrganizationControllerApi = exports.OrganizationControllerApiFactory = exports.OrganizationControllerApiFp = exports.OrganizationControllerApiFetchParamCreator = exports.ImportExportControllerApi = exports.ImportExportControllerApiFactory = exports.ImportExportControllerApiFp = exports.ImportExportControllerApiFetchParamCreator = exports.FileControllerApi = exports.FileControllerApiFactory = exports.FileControllerApiFp = exports.FileControllerApiFetchParamCreator = exports.FeatureFlagsControllerApi = exports.FeatureFlagsControllerApiFactory = exports.FeatureFlagsControllerApiFp = exports.FeatureFlagsControllerApiFetchParamCreator = exports.FacilityControllerApi = exports.FacilityControllerApiFactory = exports.FacilityControllerApiFp = exports.FacilityControllerApiFetchParamCreator = exports.EventControllerApi = exports.EventControllerApiFactory = exports.EventControllerApiFp = exports.EventControllerApiFetchParamCreator = exports.ZoneOffsetTransitionRule = exports.UserPermissionDto = exports.UserPermission = exports.UserDto = exports.User = exports.UpdateOrganizationDto = exports.TableUserDto = exports.RoleDto = exports.Role = exports.Permission = exports.ParametersDto = exports.PageableDto = exports.OrganizationFeatureFlagsDto = exports.Organization = exports.FacilityFeatureFlagsDto = exports.FacilityDto = exports.Facility = exports.CreateStaffUserDto = exports.CancellationReason = exports.RequiredError = exports.BaseAPI = exports.COLLECTION_FORMATS = void 0;
exports.UserPermissionControllerApi = exports.UserPermissionControllerApiFactory = exports.UserPermissionControllerApiFp = exports.UserPermissionControllerApiFetchParamCreator = void 0;
var url = __importStar(require("url"));
var portableFetch = __importStar(require("portable-fetch"));
var BASE_PATH = "//localhost:8080/".replace(/\/+$/, "");
/**
 *
 * @export
 */
exports.COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration, basePath, fetch) {
        if (basePath === void 0) { basePath = BASE_PATH; }
        if (fetch === void 0) { fetch = portableFetch; }
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
    return BaseAPI;
}());
exports.BaseAPI = BaseAPI;
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        return _this;
    }
    return RequiredError;
}(Error));
exports.RequiredError = RequiredError;
/**
 * @export
 * @namespace CancellationReason
 */
var CancellationReason;
(function (CancellationReason) {
    /**
     * @export
     * @enum {string}
     */
    var ForStatusesEnum;
    (function (ForStatusesEnum) {
        ForStatusesEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        ForStatusesEnum["CANCELLED"] = "CANCELLED";
        ForStatusesEnum["IN_PROGRESS"] = "IN_PROGRESS";
        ForStatusesEnum["SIGNED"] = "SIGNED";
        ForStatusesEnum["APPROVED"] = "APPROVED";
        ForStatusesEnum["ARCHIVED"] = "ARCHIVED";
        ForStatusesEnum["INCOMPLETE"] = "INCOMPLETE";
    })(ForStatusesEnum = CancellationReason.ForStatusesEnum || (CancellationReason.ForStatusesEnum = {}));
})(CancellationReason = exports.CancellationReason || (exports.CancellationReason = {}));
/**
 * @export
 * @namespace CreateStaffUserDto
 */
var CreateStaffUserDto;
(function (CreateStaffUserDto) {
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["RESIDENT"] = "RESIDENT";
        TypeEnum["CLERK"] = "CLERK";
        TypeEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
    })(TypeEnum = CreateStaffUserDto.TypeEnum || (CreateStaffUserDto.TypeEnum = {}));
})(CreateStaffUserDto = exports.CreateStaffUserDto || (exports.CreateStaffUserDto = {}));
/**
 * @export
 * @namespace Facility
 */
var Facility;
(function (Facility) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = Facility.StatusEnum || (Facility.StatusEnum = {}));
})(Facility = exports.Facility || (exports.Facility = {}));
/**
 * @export
 * @namespace FacilityDto
 */
var FacilityDto;
(function (FacilityDto) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = FacilityDto.StatusEnum || (FacilityDto.StatusEnum = {}));
})(FacilityDto = exports.FacilityDto || (exports.FacilityDto = {}));
/**
 * @export
 * @namespace FacilityFeatureFlagsDto
 */
var FacilityFeatureFlagsDto;
(function (FacilityFeatureFlagsDto) {
    /**
     * @export
     * @enum {string}
     */
    var FeatureFlagsEnum;
    (function (FeatureFlagsEnum) {
        FeatureFlagsEnum["PCC_BASIC"] = "PCC_BASIC";
        FeatureFlagsEnum["PCC_PREMIUM"] = "PCC_PREMIUM";
        FeatureFlagsEnum["PCC_SYNC"] = "PCC_SYNC";
        FeatureFlagsEnum["ARBITRATION_EMAIL"] = "ARBITRATION_EMAIL";
        FeatureFlagsEnum["MARQUIS_COVID_EMAIL"] = "MARQUIS_COVID_EMAIL";
        FeatureFlagsEnum["ORG_ADMIN_ONLY_DELETE_ADMISSIONS"] = "ORG_ADMIN_ONLY_DELETE_ADMISSIONS";
    })(FeatureFlagsEnum = FacilityFeatureFlagsDto.FeatureFlagsEnum || (FacilityFeatureFlagsDto.FeatureFlagsEnum = {}));
})(FacilityFeatureFlagsDto = exports.FacilityFeatureFlagsDto || (exports.FacilityFeatureFlagsDto = {}));
/**
 * @export
 * @namespace Organization
 */
var Organization;
(function (Organization) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = Organization.StatusEnum || (Organization.StatusEnum = {}));
})(Organization = exports.Organization || (exports.Organization = {}));
/**
 * @export
 * @namespace OrganizationFeatureFlagsDto
 */
var OrganizationFeatureFlagsDto;
(function (OrganizationFeatureFlagsDto) {
    /**
     * @export
     * @enum {string}
     */
    var FeatureFlagsEnum;
    (function (FeatureFlagsEnum) {
        FeatureFlagsEnum["FIELDS"] = "CLEAR_HIDDEN_FIELDS";
    })(FeatureFlagsEnum = OrganizationFeatureFlagsDto.FeatureFlagsEnum || (OrganizationFeatureFlagsDto.FeatureFlagsEnum = {}));
})(OrganizationFeatureFlagsDto = exports.OrganizationFeatureFlagsDto || (exports.OrganizationFeatureFlagsDto = {}));
/**
 * @export
 * @namespace PageableDto
 */
var PageableDto;
(function (PageableDto) {
    /**
     * @export
     * @enum {string}
     */
    var DirectionEnum;
    (function (DirectionEnum) {
        DirectionEnum["ASC"] = "ASC";
        DirectionEnum["DESC"] = "DESC";
    })(DirectionEnum = PageableDto.DirectionEnum || (PageableDto.DirectionEnum = {}));
})(PageableDto = exports.PageableDto || (exports.PageableDto = {}));
/**
 * @export
 * @namespace ParametersDto
 */
var ParametersDto;
(function (ParametersDto) {
    /**
     * @export
     * @enum {string}
     */
    var RolesEnum;
    (function (RolesEnum) {
        RolesEnum["RESIDENT"] = "RESIDENT";
        RolesEnum["CLERK"] = "CLERK";
        RolesEnum["FACILITY_ADMIN"] = "FACILITY_ADMIN";
        RolesEnum["ADMISSIONS_DIRECTOR"] = "ADMISSIONS_DIRECTOR";
        RolesEnum["REGIONAL_ADMIN"] = "REGIONAL_ADMIN";
        RolesEnum["ORGANIZATION_ADMIN"] = "ORGANIZATION_ADMIN";
        RolesEnum["RESIDE_ADMIN"] = "RESIDE_ADMIN";
    })(RolesEnum = ParametersDto.RolesEnum || (ParametersDto.RolesEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusesEnum;
    (function (StatusesEnum) {
        StatusesEnum["ACTIVE"] = "ACTIVE";
        StatusesEnum["DELETED"] = "DELETED";
        StatusesEnum["NEW"] = "NEW";
    })(StatusesEnum = ParametersDto.StatusesEnum || (ParametersDto.StatusesEnum = {}));
})(ParametersDto = exports.ParametersDto || (exports.ParametersDto = {}));
/**
 * @export
 * @namespace Permission
 */
var Permission;
(function (Permission) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = Permission.StatusEnum || (Permission.StatusEnum = {}));
})(Permission = exports.Permission || (exports.Permission = {}));
/**
 * @export
 * @namespace Role
 */
var Role;
(function (Role) {
    /**
     * @export
     * @enum {string}
     */
    var NameEnum;
    (function (NameEnum) {
        NameEnum["RESIDENT"] = "RESIDENT";
        NameEnum["CLERK"] = "CLERK";
        NameEnum["FACILITY_ADMIN"] = "FACILITY_ADMIN";
        NameEnum["ADMISSIONS_DIRECTOR"] = "ADMISSIONS_DIRECTOR";
        NameEnum["REGIONAL_ADMIN"] = "REGIONAL_ADMIN";
        NameEnum["ORGANIZATION_ADMIN"] = "ORGANIZATION_ADMIN";
        NameEnum["RESIDE_ADMIN"] = "RESIDE_ADMIN";
    })(NameEnum = Role.NameEnum || (Role.NameEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var ScopeEnum;
    (function (ScopeEnum) {
        ScopeEnum["RESIDENT"] = "RESIDENT";
        ScopeEnum["FACILITY"] = "FACILITY";
        ScopeEnum["ORGANIZATION"] = "ORGANIZATION";
        ScopeEnum["RESIDE"] = "RESIDE";
    })(ScopeEnum = Role.ScopeEnum || (Role.ScopeEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = Role.StatusEnum || (Role.StatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["RESIDENT"] = "RESIDENT";
        TypeEnum["CLERK"] = "CLERK";
        TypeEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
    })(TypeEnum = Role.TypeEnum || (Role.TypeEnum = {}));
})(Role = exports.Role || (exports.Role = {}));
/**
 * @export
 * @namespace RoleDto
 */
var RoleDto;
(function (RoleDto) {
    /**
     * @export
     * @enum {string}
     */
    var NameEnum;
    (function (NameEnum) {
        NameEnum["RESIDENT"] = "RESIDENT";
        NameEnum["CLERK"] = "CLERK";
        NameEnum["FACILITY_ADMIN"] = "FACILITY_ADMIN";
        NameEnum["ADMISSIONS_DIRECTOR"] = "ADMISSIONS_DIRECTOR";
        NameEnum["REGIONAL_ADMIN"] = "REGIONAL_ADMIN";
        NameEnum["ORGANIZATION_ADMIN"] = "ORGANIZATION_ADMIN";
        NameEnum["RESIDE_ADMIN"] = "RESIDE_ADMIN";
    })(NameEnum = RoleDto.NameEnum || (RoleDto.NameEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var ScopeEnum;
    (function (ScopeEnum) {
        ScopeEnum["RESIDENT"] = "RESIDENT";
        ScopeEnum["FACILITY"] = "FACILITY";
        ScopeEnum["ORGANIZATION"] = "ORGANIZATION";
        ScopeEnum["RESIDE"] = "RESIDE";
    })(ScopeEnum = RoleDto.ScopeEnum || (RoleDto.ScopeEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = RoleDto.StatusEnum || (RoleDto.StatusEnum = {}));
})(RoleDto = exports.RoleDto || (exports.RoleDto = {}));
/**
 * @export
 * @namespace TableUserDto
 */
var TableUserDto;
(function (TableUserDto) {
    /**
     * @export
     * @enum {string}
     */
    var PermissionsEnum;
    (function (PermissionsEnum) {
        PermissionsEnum["ADMISSION_COMPLETION_RATE"] = "ADMISSION_COMPLETION_RATE";
        PermissionsEnum["PATIENTS_WITH_MEDICAL_SUPPLEMENT"] = "PATIENTS_WITH_MEDICAL_SUPPLEMENT";
        PermissionsEnum["PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT"] = "PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT";
        PermissionsEnum["TRUST_FUND_POLICY"] = "TRUST_FUND_POLICY";
        PermissionsEnum["ADMISSIONS_WITH_ADVANCED_DIRECTIVES"] = "ADMISSIONS_WITH_ADVANCED_DIRECTIVES";
        PermissionsEnum["RESIDENTS_ACCEPTED_OBRA_WITHIN_24_72_HOURS"] = "RESIDENTS_ACCEPTED_OBRA_WITHIN_24_72_HOURS";
        PermissionsEnum["LAUNDRY_CONSENT"] = "LAUNDRY_CONSENT";
        PermissionsEnum["SURROGATE_DECISION_RESIDENTS_REQUESTING_FOR_MORE_INFORMATION"] = "SURROGATE_DECISION_RESIDENTS_REQUESTING_FOR_MORE_INFORMATION";
        PermissionsEnum["ADMISSION_IN_TOTAL"] = "ADMISSION_IN_TOTAL";
        PermissionsEnum["AVG_TIME_SPENT_TO_CLOSE_ADMISSION"] = "AVG_TIME_SPENT_TO_CLOSE_ADMISSION";
        PermissionsEnum["ESTIMATED_COST_TRACKED_BY_ROLE_AND_DATE"] = "ESTIMATED_COST_TRACKED_BY_ROLE_AND_DATE";
        PermissionsEnum["ARBITRATION_AGREEMENT"] = "ARBITRATION_AGREEMENT";
        PermissionsEnum["SLIDE_INTERACTIONS"] = "SLIDE_INTERACTIONS";
        PermissionsEnum["READMISSION_RATE"] = "READMISSION_RATE";
        PermissionsEnum["AGE_OF_HELP_FLAGS"] = "AGE_OF_HELP_FLAGS";
        PermissionsEnum["INCOMES_AND_PERSONAL_RESOURCE"] = "INCOMES_AND_PERSONAL_RESOURCE";
        PermissionsEnum["MOST_FREQUENTLY_FLAGGED_SLIDES"] = "MOST_FREQUENTLY_FLAGGED_SLIDES";
        PermissionsEnum["COMPLETION_RATE_OF_ADMISSION_UNDER_90"] = "COMPLETION_RATE_OF_ADMISSION_UNDER_90";
        PermissionsEnum["FUNERAL"] = "FUNERAL";
        PermissionsEnum["MEDICARE_WAIVER"] = "MEDICARE_WAIVER";
        PermissionsEnum["SURROGATE_DECISION_MAKING"] = "SURROGATE_DECISION_MAKING";
        PermissionsEnum["ADMISSIONS_COMPLETED_WITH_REPRESENT"] = "ADMISSIONS_COMPLETED_WITH_REPRESENT";
        PermissionsEnum["AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION"] = "AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION";
        PermissionsEnum["PNEUMOCOCCAL_AND_INFLUENZA_VACCINES"] = "PNEUMOCOCCAL_AND_INFLUENZA_VACCINES";
        PermissionsEnum["PNEUMOCOCCAL_VACCINES"] = "PNEUMOCOCCAL_VACCINES";
        PermissionsEnum["INFLUENZA_VACCINES"] = "INFLUENZA_VACCINES";
        PermissionsEnum["RESIDENTS_REQUESTING_APPLICATION_FROM_MEDICAID"] = "RESIDENTS_REQUESTING_APPLICATION_FROM_MEDICAID";
        PermissionsEnum["RESIDENTS_REQUESTING_FOR_HELP_WITH_CREATING_ADVACED_DIRECTIVE"] = "RESIDENTS_REQUESTING_FOR_HELP_WITH_CREATING_ADVACED_DIRECTIVE";
        PermissionsEnum["ARBITRATION_AGREEMENT_COMPLETED"] = "ARBITRATION_AGREEMENT_COMPLETED";
        PermissionsEnum["CONTRACT_SIGN"] = "CONTRACT_SIGN";
        PermissionsEnum["ANALYTICS_ADMISSION_IN_TOTAL"] = "ANALYTICS_ADMISSION_IN_TOTAL";
        PermissionsEnum["APPROVED_ARCHIVED_ADMISSIONS"] = "APPROVED_ARCHIVED_ADMISSIONS";
        PermissionsEnum["RESIDENTS_WITH_MANAGED_MEDICAID"] = "RESIDENTS_WITH_MANAGED_MEDICAID";
        PermissionsEnum["RESIDENTS_WITH_DNR"] = "RESIDENTS_WITH_DNR";
        PermissionsEnum["EXISTING_ADVANCED_DIRECTIVE_PROVIDED"] = "EXISTING_ADVANCED_DIRECTIVE_PROVIDED";
        PermissionsEnum["FACILITY_CHANGES"] = "FACILITY_CHANGES";
        PermissionsEnum["ESTIMATED_COST_TRACKED_BY_FACILITY"] = "ESTIMATED_COST_TRACKED_BY_FACILITY";
        PermissionsEnum["PAYER_SOURCE_AGE_65_75"] = "PAYER_SOURCE_AGE_65_75";
        PermissionsEnum["PAYER_SOURCE_AGE_75_85"] = "PAYER_SOURCE_AGE_75_85";
        PermissionsEnum["PAYER_SOURCE_AGE_85"] = "PAYER_SOURCE_AGE_85";
        PermissionsEnum["COMPLETION_TIME"] = "COMPLETION_TIME";
        PermissionsEnum["COMPLETION_OVERALL"] = "COMPLETION_OVERALL";
        PermissionsEnum["PHOTOS"] = "PHOTOS";
        PermissionsEnum["STAFF_ADMISSIONS"] = "STAFF_ADMISSIONS";
        PermissionsEnum["CRIMINAL_RECORD_CHECKBOX"] = "CRIMINAL_RECORD_CHECKBOX";
        PermissionsEnum["COPY_OF_SURROGATE_CHECKBOX"] = "COPY_OF_SURROGATE_CHECKBOX";
        PermissionsEnum["ADVANCED_DIRECTIVE_CHECKBOX"] = "ADVANCED_DIRECTIVE_CHECKBOX";
        PermissionsEnum["OBRA_CHECKBOX"] = "OBRA_CHECKBOX";
        PermissionsEnum["PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE"] = "PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE";
        PermissionsEnum["COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED"] = "COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED";
        PermissionsEnum["CHECKLIST_ITEMS"] = "CHECKLIST_ITEMS";
        PermissionsEnum["QUICKSIGHT_DASHBOARDS"] = "QUICKSIGHT_DASHBOARDS";
    })(PermissionsEnum = TableUserDto.PermissionsEnum || (TableUserDto.PermissionsEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var RoleEnum;
    (function (RoleEnum) {
        RoleEnum["RESIDENT"] = "RESIDENT";
        RoleEnum["CLERK"] = "CLERK";
        RoleEnum["FACILITY_ADMIN"] = "FACILITY_ADMIN";
        RoleEnum["ADMISSIONS_DIRECTOR"] = "ADMISSIONS_DIRECTOR";
        RoleEnum["REGIONAL_ADMIN"] = "REGIONAL_ADMIN";
        RoleEnum["ORGANIZATION_ADMIN"] = "ORGANIZATION_ADMIN";
        RoleEnum["RESIDE_ADMIN"] = "RESIDE_ADMIN";
    })(RoleEnum = TableUserDto.RoleEnum || (TableUserDto.RoleEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["DELETED"] = "DELETED";
        StatusEnum["NEW"] = "NEW";
    })(StatusEnum = TableUserDto.StatusEnum || (TableUserDto.StatusEnum = {}));
})(TableUserDto = exports.TableUserDto || (exports.TableUserDto = {}));
/**
 * @export
 * @namespace UpdateOrganizationDto
 */
var UpdateOrganizationDto;
(function (UpdateOrganizationDto) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = UpdateOrganizationDto.StatusEnum || (UpdateOrganizationDto.StatusEnum = {}));
})(UpdateOrganizationDto = exports.UpdateOrganizationDto || (exports.UpdateOrganizationDto = {}));
/**
 * @export
 * @namespace User
 */
var User;
(function (User) {
    /**
     * @export
     * @enum {string}
     */
    var NotificationChannelEnum;
    (function (NotificationChannelEnum) {
        NotificationChannelEnum["EMAIL"] = "EMAIL";
        NotificationChannelEnum["SMS"] = "SMS";
    })(NotificationChannelEnum = User.NotificationChannelEnum || (User.NotificationChannelEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["DELETED"] = "DELETED";
        StatusEnum["NEW"] = "NEW";
    })(StatusEnum = User.StatusEnum || (User.StatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["RESIDENT"] = "RESIDENT";
        TypeEnum["CLERK"] = "CLERK";
        TypeEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
    })(TypeEnum = User.TypeEnum || (User.TypeEnum = {}));
})(User = exports.User || (exports.User = {}));
/**
 * @export
 * @namespace UserDto
 */
var UserDto;
(function (UserDto) {
    /**
     * @export
     * @enum {string}
     */
    var NotificationChannelEnum;
    (function (NotificationChannelEnum) {
        NotificationChannelEnum["EMAIL"] = "EMAIL";
        NotificationChannelEnum["SMS"] = "SMS";
    })(NotificationChannelEnum = UserDto.NotificationChannelEnum || (UserDto.NotificationChannelEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["DELETED"] = "DELETED";
        StatusEnum["NEW"] = "NEW";
    })(StatusEnum = UserDto.StatusEnum || (UserDto.StatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["RESIDENT"] = "RESIDENT";
        TypeEnum["CLERK"] = "CLERK";
        TypeEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
    })(TypeEnum = UserDto.TypeEnum || (UserDto.TypeEnum = {}));
})(UserDto = exports.UserDto || (exports.UserDto = {}));
/**
 * @export
 * @namespace UserPermission
 */
var UserPermission;
(function (UserPermission) {
    /**
     * @export
     * @enum {string}
     */
    var NameEnum;
    (function (NameEnum) {
        NameEnum["ADMISSION_COMPLETION_RATE"] = "ADMISSION_COMPLETION_RATE";
        NameEnum["PATIENTS_WITH_MEDICAL_SUPPLEMENT"] = "PATIENTS_WITH_MEDICAL_SUPPLEMENT";
        NameEnum["PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT"] = "PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT";
        NameEnum["TRUST_FUND_POLICY"] = "TRUST_FUND_POLICY";
        NameEnum["ADMISSIONS_WITH_ADVANCED_DIRECTIVES"] = "ADMISSIONS_WITH_ADVANCED_DIRECTIVES";
        NameEnum["RESIDENTS_ACCEPTED_OBRA_WITHIN_24_72_HOURS"] = "RESIDENTS_ACCEPTED_OBRA_WITHIN_24_72_HOURS";
        NameEnum["LAUNDRY_CONSENT"] = "LAUNDRY_CONSENT";
        NameEnum["SURROGATE_DECISION_RESIDENTS_REQUESTING_FOR_MORE_INFORMATION"] = "SURROGATE_DECISION_RESIDENTS_REQUESTING_FOR_MORE_INFORMATION";
        NameEnum["ADMISSION_IN_TOTAL"] = "ADMISSION_IN_TOTAL";
        NameEnum["AVG_TIME_SPENT_TO_CLOSE_ADMISSION"] = "AVG_TIME_SPENT_TO_CLOSE_ADMISSION";
        NameEnum["ESTIMATED_COST_TRACKED_BY_ROLE_AND_DATE"] = "ESTIMATED_COST_TRACKED_BY_ROLE_AND_DATE";
        NameEnum["ARBITRATION_AGREEMENT"] = "ARBITRATION_AGREEMENT";
        NameEnum["SLIDE_INTERACTIONS"] = "SLIDE_INTERACTIONS";
        NameEnum["READMISSION_RATE"] = "READMISSION_RATE";
        NameEnum["AGE_OF_HELP_FLAGS"] = "AGE_OF_HELP_FLAGS";
        NameEnum["INCOMES_AND_PERSONAL_RESOURCE"] = "INCOMES_AND_PERSONAL_RESOURCE";
        NameEnum["MOST_FREQUENTLY_FLAGGED_SLIDES"] = "MOST_FREQUENTLY_FLAGGED_SLIDES";
        NameEnum["COMPLETION_RATE_OF_ADMISSION_UNDER_90"] = "COMPLETION_RATE_OF_ADMISSION_UNDER_90";
        NameEnum["FUNERAL"] = "FUNERAL";
        NameEnum["MEDICARE_WAIVER"] = "MEDICARE_WAIVER";
        NameEnum["SURROGATE_DECISION_MAKING"] = "SURROGATE_DECISION_MAKING";
        NameEnum["ADMISSIONS_COMPLETED_WITH_REPRESENT"] = "ADMISSIONS_COMPLETED_WITH_REPRESENT";
        NameEnum["AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION"] = "AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION";
        NameEnum["PNEUMOCOCCAL_AND_INFLUENZA_VACCINES"] = "PNEUMOCOCCAL_AND_INFLUENZA_VACCINES";
        NameEnum["PNEUMOCOCCAL_VACCINES"] = "PNEUMOCOCCAL_VACCINES";
        NameEnum["INFLUENZA_VACCINES"] = "INFLUENZA_VACCINES";
        NameEnum["RESIDENTS_REQUESTING_APPLICATION_FROM_MEDICAID"] = "RESIDENTS_REQUESTING_APPLICATION_FROM_MEDICAID";
        NameEnum["RESIDENTS_REQUESTING_FOR_HELP_WITH_CREATING_ADVACED_DIRECTIVE"] = "RESIDENTS_REQUESTING_FOR_HELP_WITH_CREATING_ADVACED_DIRECTIVE";
        NameEnum["ARBITRATION_AGREEMENT_COMPLETED"] = "ARBITRATION_AGREEMENT_COMPLETED";
        NameEnum["CONTRACT_SIGN"] = "CONTRACT_SIGN";
        NameEnum["ANALYTICS_ADMISSION_IN_TOTAL"] = "ANALYTICS_ADMISSION_IN_TOTAL";
        NameEnum["APPROVED_ARCHIVED_ADMISSIONS"] = "APPROVED_ARCHIVED_ADMISSIONS";
        NameEnum["RESIDENTS_WITH_MANAGED_MEDICAID"] = "RESIDENTS_WITH_MANAGED_MEDICAID";
        NameEnum["RESIDENTS_WITH_DNR"] = "RESIDENTS_WITH_DNR";
        NameEnum["EXISTING_ADVANCED_DIRECTIVE_PROVIDED"] = "EXISTING_ADVANCED_DIRECTIVE_PROVIDED";
        NameEnum["FACILITY_CHANGES"] = "FACILITY_CHANGES";
        NameEnum["ESTIMATED_COST_TRACKED_BY_FACILITY"] = "ESTIMATED_COST_TRACKED_BY_FACILITY";
        NameEnum["PAYER_SOURCE_AGE_65_75"] = "PAYER_SOURCE_AGE_65_75";
        NameEnum["PAYER_SOURCE_AGE_75_85"] = "PAYER_SOURCE_AGE_75_85";
        NameEnum["PAYER_SOURCE_AGE_85"] = "PAYER_SOURCE_AGE_85";
        NameEnum["COMPLETION_TIME"] = "COMPLETION_TIME";
        NameEnum["COMPLETION_OVERALL"] = "COMPLETION_OVERALL";
        NameEnum["PHOTOS"] = "PHOTOS";
        NameEnum["STAFF_ADMISSIONS"] = "STAFF_ADMISSIONS";
        NameEnum["CRIMINAL_RECORD_CHECKBOX"] = "CRIMINAL_RECORD_CHECKBOX";
        NameEnum["COPY_OF_SURROGATE_CHECKBOX"] = "COPY_OF_SURROGATE_CHECKBOX";
        NameEnum["ADVANCED_DIRECTIVE_CHECKBOX"] = "ADVANCED_DIRECTIVE_CHECKBOX";
        NameEnum["OBRA_CHECKBOX"] = "OBRA_CHECKBOX";
        NameEnum["PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE"] = "PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE";
        NameEnum["COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED"] = "COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED";
        NameEnum["CHECKLIST_ITEMS"] = "CHECKLIST_ITEMS";
        NameEnum["QUICKSIGHT_DASHBOARDS"] = "QUICKSIGHT_DASHBOARDS";
    })(NameEnum = UserPermission.NameEnum || (UserPermission.NameEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = UserPermission.StatusEnum || (UserPermission.StatusEnum = {}));
})(UserPermission = exports.UserPermission || (exports.UserPermission = {}));
/**
 * @export
 * @namespace UserPermissionDto
 */
var UserPermissionDto;
(function (UserPermissionDto) {
    /**
     * @export
     * @enum {string}
     */
    var NameEnum;
    (function (NameEnum) {
        NameEnum["ADMISSION_COMPLETION_RATE"] = "ADMISSION_COMPLETION_RATE";
        NameEnum["PATIENTS_WITH_MEDICAL_SUPPLEMENT"] = "PATIENTS_WITH_MEDICAL_SUPPLEMENT";
        NameEnum["PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT"] = "PATIENTS_WITHOUT_MEDICAL_SUPPLEMENT";
        NameEnum["TRUST_FUND_POLICY"] = "TRUST_FUND_POLICY";
        NameEnum["ADMISSIONS_WITH_ADVANCED_DIRECTIVES"] = "ADMISSIONS_WITH_ADVANCED_DIRECTIVES";
        NameEnum["RESIDENTS_ACCEPTED_OBRA_WITHIN_24_72_HOURS"] = "RESIDENTS_ACCEPTED_OBRA_WITHIN_24_72_HOURS";
        NameEnum["LAUNDRY_CONSENT"] = "LAUNDRY_CONSENT";
        NameEnum["SURROGATE_DECISION_RESIDENTS_REQUESTING_FOR_MORE_INFORMATION"] = "SURROGATE_DECISION_RESIDENTS_REQUESTING_FOR_MORE_INFORMATION";
        NameEnum["ADMISSION_IN_TOTAL"] = "ADMISSION_IN_TOTAL";
        NameEnum["AVG_TIME_SPENT_TO_CLOSE_ADMISSION"] = "AVG_TIME_SPENT_TO_CLOSE_ADMISSION";
        NameEnum["ESTIMATED_COST_TRACKED_BY_ROLE_AND_DATE"] = "ESTIMATED_COST_TRACKED_BY_ROLE_AND_DATE";
        NameEnum["ARBITRATION_AGREEMENT"] = "ARBITRATION_AGREEMENT";
        NameEnum["SLIDE_INTERACTIONS"] = "SLIDE_INTERACTIONS";
        NameEnum["READMISSION_RATE"] = "READMISSION_RATE";
        NameEnum["AGE_OF_HELP_FLAGS"] = "AGE_OF_HELP_FLAGS";
        NameEnum["INCOMES_AND_PERSONAL_RESOURCE"] = "INCOMES_AND_PERSONAL_RESOURCE";
        NameEnum["MOST_FREQUENTLY_FLAGGED_SLIDES"] = "MOST_FREQUENTLY_FLAGGED_SLIDES";
        NameEnum["COMPLETION_RATE_OF_ADMISSION_UNDER_90"] = "COMPLETION_RATE_OF_ADMISSION_UNDER_90";
        NameEnum["FUNERAL"] = "FUNERAL";
        NameEnum["MEDICARE_WAIVER"] = "MEDICARE_WAIVER";
        NameEnum["SURROGATE_DECISION_MAKING"] = "SURROGATE_DECISION_MAKING";
        NameEnum["ADMISSIONS_COMPLETED_WITH_REPRESENT"] = "ADMISSIONS_COMPLETED_WITH_REPRESENT";
        NameEnum["AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION"] = "AVG_TIME_SPENT_OF_EACH_STAGE_OF_ADMISSION";
        NameEnum["PNEUMOCOCCAL_AND_INFLUENZA_VACCINES"] = "PNEUMOCOCCAL_AND_INFLUENZA_VACCINES";
        NameEnum["PNEUMOCOCCAL_VACCINES"] = "PNEUMOCOCCAL_VACCINES";
        NameEnum["INFLUENZA_VACCINES"] = "INFLUENZA_VACCINES";
        NameEnum["RESIDENTS_REQUESTING_APPLICATION_FROM_MEDICAID"] = "RESIDENTS_REQUESTING_APPLICATION_FROM_MEDICAID";
        NameEnum["RESIDENTS_REQUESTING_FOR_HELP_WITH_CREATING_ADVACED_DIRECTIVE"] = "RESIDENTS_REQUESTING_FOR_HELP_WITH_CREATING_ADVACED_DIRECTIVE";
        NameEnum["ARBITRATION_AGREEMENT_COMPLETED"] = "ARBITRATION_AGREEMENT_COMPLETED";
        NameEnum["CONTRACT_SIGN"] = "CONTRACT_SIGN";
        NameEnum["ANALYTICS_ADMISSION_IN_TOTAL"] = "ANALYTICS_ADMISSION_IN_TOTAL";
        NameEnum["APPROVED_ARCHIVED_ADMISSIONS"] = "APPROVED_ARCHIVED_ADMISSIONS";
        NameEnum["RESIDENTS_WITH_MANAGED_MEDICAID"] = "RESIDENTS_WITH_MANAGED_MEDICAID";
        NameEnum["RESIDENTS_WITH_DNR"] = "RESIDENTS_WITH_DNR";
        NameEnum["EXISTING_ADVANCED_DIRECTIVE_PROVIDED"] = "EXISTING_ADVANCED_DIRECTIVE_PROVIDED";
        NameEnum["FACILITY_CHANGES"] = "FACILITY_CHANGES";
        NameEnum["ESTIMATED_COST_TRACKED_BY_FACILITY"] = "ESTIMATED_COST_TRACKED_BY_FACILITY";
        NameEnum["PAYER_SOURCE_AGE_65_75"] = "PAYER_SOURCE_AGE_65_75";
        NameEnum["PAYER_SOURCE_AGE_75_85"] = "PAYER_SOURCE_AGE_75_85";
        NameEnum["PAYER_SOURCE_AGE_85"] = "PAYER_SOURCE_AGE_85";
        NameEnum["COMPLETION_TIME"] = "COMPLETION_TIME";
        NameEnum["COMPLETION_OVERALL"] = "COMPLETION_OVERALL";
        NameEnum["PHOTOS"] = "PHOTOS";
        NameEnum["STAFF_ADMISSIONS"] = "STAFF_ADMISSIONS";
        NameEnum["CRIMINAL_RECORD_CHECKBOX"] = "CRIMINAL_RECORD_CHECKBOX";
        NameEnum["COPY_OF_SURROGATE_CHECKBOX"] = "COPY_OF_SURROGATE_CHECKBOX";
        NameEnum["ADVANCED_DIRECTIVE_CHECKBOX"] = "ADVANCED_DIRECTIVE_CHECKBOX";
        NameEnum["OBRA_CHECKBOX"] = "OBRA_CHECKBOX";
        NameEnum["PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE"] = "PERCENTAGE_OF_ADMISSIONS_COMPLETED_BY_REPRESENTATIVE";
        NameEnum["COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED"] = "COMPLETION_RATE_ONCE_ADMISSION_IS_SIGNED";
        NameEnum["CHECKLIST_ITEMS"] = "CHECKLIST_ITEMS";
        NameEnum["QUICKSIGHT_DASHBOARDS"] = "QUICKSIGHT_DASHBOARDS";
    })(NameEnum = UserPermissionDto.NameEnum || (UserPermissionDto.NameEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["ACTIVE"] = "ACTIVE";
        StatusEnum["INACTIVE"] = "INACTIVE";
        StatusEnum["DELETED"] = "DELETED";
    })(StatusEnum = UserPermissionDto.StatusEnum || (UserPermissionDto.StatusEnum = {}));
})(UserPermissionDto = exports.UserPermissionDto || (exports.UserPermissionDto = {}));
/**
 * @export
 * @namespace ZoneOffsetTransitionRule
 */
var ZoneOffsetTransitionRule;
(function (ZoneOffsetTransitionRule) {
    /**
     * @export
     * @enum {string}
     */
    var DayOfWeekEnum;
    (function (DayOfWeekEnum) {
        DayOfWeekEnum["MONDAY"] = "MONDAY";
        DayOfWeekEnum["TUESDAY"] = "TUESDAY";
        DayOfWeekEnum["WEDNESDAY"] = "WEDNESDAY";
        DayOfWeekEnum["THURSDAY"] = "THURSDAY";
        DayOfWeekEnum["FRIDAY"] = "FRIDAY";
        DayOfWeekEnum["SATURDAY"] = "SATURDAY";
        DayOfWeekEnum["SUNDAY"] = "SUNDAY";
    })(DayOfWeekEnum = ZoneOffsetTransitionRule.DayOfWeekEnum || (ZoneOffsetTransitionRule.DayOfWeekEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var MonthEnum;
    (function (MonthEnum) {
        MonthEnum["JANUARY"] = "JANUARY";
        MonthEnum["FEBRUARY"] = "FEBRUARY";
        MonthEnum["MARCH"] = "MARCH";
        MonthEnum["APRIL"] = "APRIL";
        MonthEnum["MAY"] = "MAY";
        MonthEnum["JUNE"] = "JUNE";
        MonthEnum["JULY"] = "JULY";
        MonthEnum["AUGUST"] = "AUGUST";
        MonthEnum["SEPTEMBER"] = "SEPTEMBER";
        MonthEnum["OCTOBER"] = "OCTOBER";
        MonthEnum["NOVEMBER"] = "NOVEMBER";
        MonthEnum["DECEMBER"] = "DECEMBER";
    })(MonthEnum = ZoneOffsetTransitionRule.MonthEnum || (ZoneOffsetTransitionRule.MonthEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TimeDefinitionEnum;
    (function (TimeDefinitionEnum) {
        TimeDefinitionEnum["UTC"] = "UTC";
        TimeDefinitionEnum["WALL"] = "WALL";
        TimeDefinitionEnum["STANDARD"] = "STANDARD";
    })(TimeDefinitionEnum = ZoneOffsetTransitionRule.TimeDefinitionEnum || (ZoneOffsetTransitionRule.TimeDefinitionEnum = {}));
})(ZoneOffsetTransitionRule = exports.ZoneOffsetTransitionRule || (exports.ZoneOffsetTransitionRule = {}));
/**
 * EventControllerApi - fetch parameter creator
 * @export
 */
var EventControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Store event into storage
         * @param {CreateEventDto} body createEventDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createEventUsingPOST.');
            }
            var localVarPath = "/api/events";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("CreateEventDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.EventControllerApiFetchParamCreator = EventControllerApiFetchParamCreator;
/**
 * EventControllerApi - functional programming interface
 * @export
 */
var EventControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Store event into storage
         * @param {CreateEventDto} body createEventDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.EventControllerApiFetchParamCreator)(configuration).createEventUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.EventControllerApiFp = EventControllerApiFp;
/**
 * EventControllerApi - factory interface
 * @export
 */
var EventControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Store event into storage
         * @param {CreateEventDto} body createEventDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventUsingPOST: function (body, authorization, options) {
            return (0, exports.EventControllerApiFp)(configuration).createEventUsingPOST(body, authorization, options)(fetch, basePath);
        },
    };
};
exports.EventControllerApiFactory = EventControllerApiFactory;
/**
 * EventControllerApi - object-oriented interface
 * @export
 * @class EventControllerApi
 * @extends {BaseAPI}
 */
var EventControllerApi = /** @class */ (function (_super) {
    __extends(EventControllerApi, _super);
    function EventControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Store event into storage
     * @param {CreateEventDto} body createEventDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    EventControllerApi.prototype.createEventUsingPOST = function (body, authorization, options) {
        return (0, exports.EventControllerApiFp)(this.configuration).createEventUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    return EventControllerApi;
}(BaseAPI));
exports.EventControllerApi = EventControllerApi;
/**
 * FacilityControllerApi - fetch parameter creator
 * @export
 */
var FacilityControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create new facility
         * @param {CreateFacilityDto} body createFacilityDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacilityUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createFacilityUsingPOST.');
            }
            var localVarPath = "/api/facilities";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("CreateFacilityDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create or update PCC mapping
         * @param {PccFacilityMappingDto} body facilityMapping
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateFacilityMappingUsingPUT: function (body, facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createOrUpdateFacilityMappingUsingPUT.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling createOrUpdateFacilityMappingUsingPUT.');
            }
            var localVarPath = "/api/facilities/{facilityId}/pcc-mapping"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("PccFacilityMappingDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete existing facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacilityUsingDELETE: function (facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling deleteFacilityUsingDELETE.');
            }
            var localVarPath = "/api/facilities/{facilityId}"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns found facilities based on query params
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitiesUsingGET: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/facilities";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get PCC mapping
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityMappingUsingGET: function (facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getFacilityMappingUsingGET.');
            }
            var localVarPath = "/api/facilities/{facilityId}/pcc-mapping"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieve facility settings for given facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitySettingsUsingGET: function (facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getFacilitySettingsUsingGET.');
            }
            var localVarPath = "/api/facilities/{facilityId}/settings"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns specific facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityUsingGET: function (facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getFacilityUsingGET.');
            }
            var localVarPath = "/api/facilities/{facilityId}"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Patch existing facility
         * @param {PatchFacilityDto} body patchFacilityDto
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFacilityUsingPATCH: function (body, facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling patchFacilityUsingPATCH.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling patchFacilityUsingPATCH.');
            }
            var localVarPath = "/api/facilities/{facilityId}"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("PatchFacilityDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Persist facility settings
         * @param {any} body settings
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        persistFacilitySettingsUsingPUT: function (body, facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling persistFacilitySettingsUsingPUT.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling persistFacilitySettingsUsingPUT.');
            }
            var localVarPath = "/api/facilities/{facilityId}/settings"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.FacilityControllerApiFetchParamCreator = FacilityControllerApiFetchParamCreator;
/**
 * FacilityControllerApi - functional programming interface
 * @export
 */
var FacilityControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create new facility
         * @param {CreateFacilityDto} body createFacilityDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacilityUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).createFacilityUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Create or update PCC mapping
         * @param {PccFacilityMappingDto} body facilityMapping
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateFacilityMappingUsingPUT: function (body, facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).createOrUpdateFacilityMappingUsingPUT(body, facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Delete existing facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacilityUsingDELETE: function (facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).deleteFacilityUsingDELETE(facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns found facilities based on query params
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitiesUsingGET: function (authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).getFacilitiesUsingGET(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get PCC mapping
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityMappingUsingGET: function (facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).getFacilityMappingUsingGET(facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Retrieve facility settings for given facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitySettingsUsingGET: function (facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).getFacilitySettingsUsingGET(facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns specific facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityUsingGET: function (facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).getFacilityUsingGET(facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Patch existing facility
         * @param {PatchFacilityDto} body patchFacilityDto
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFacilityUsingPATCH: function (body, facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).patchFacilityUsingPATCH(body, facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Persist facility settings
         * @param {any} body settings
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        persistFacilitySettingsUsingPUT: function (body, facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.FacilityControllerApiFetchParamCreator)(configuration).persistFacilitySettingsUsingPUT(body, facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.FacilityControllerApiFp = FacilityControllerApiFp;
/**
 * FacilityControllerApi - factory interface
 * @export
 */
var FacilityControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Create new facility
         * @param {CreateFacilityDto} body createFacilityDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFacilityUsingPOST: function (body, authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).createFacilityUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Create or update PCC mapping
         * @param {PccFacilityMappingDto} body facilityMapping
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateFacilityMappingUsingPUT: function (body, facilityId, authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).createOrUpdateFacilityMappingUsingPUT(body, facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Delete existing facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFacilityUsingDELETE: function (facilityId, authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).deleteFacilityUsingDELETE(facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns found facilities based on query params
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitiesUsingGET: function (authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).getFacilitiesUsingGET(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get PCC mapping
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityMappingUsingGET: function (facilityId, authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).getFacilityMappingUsingGET(facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Retrieve facility settings for given facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitySettingsUsingGET: function (facilityId, authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).getFacilitySettingsUsingGET(facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns specific facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityUsingGET: function (facilityId, authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).getFacilityUsingGET(facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Patch existing facility
         * @param {PatchFacilityDto} body patchFacilityDto
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFacilityUsingPATCH: function (body, facilityId, authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).patchFacilityUsingPATCH(body, facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Persist facility settings
         * @param {any} body settings
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        persistFacilitySettingsUsingPUT: function (body, facilityId, authorization, options) {
            return (0, exports.FacilityControllerApiFp)(configuration).persistFacilitySettingsUsingPUT(body, facilityId, authorization, options)(fetch, basePath);
        },
    };
};
exports.FacilityControllerApiFactory = FacilityControllerApiFactory;
/**
 * FacilityControllerApi - object-oriented interface
 * @export
 * @class FacilityControllerApi
 * @extends {BaseAPI}
 */
var FacilityControllerApi = /** @class */ (function (_super) {
    __extends(FacilityControllerApi, _super);
    function FacilityControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create new facility
     * @param {CreateFacilityDto} body createFacilityDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.createFacilityUsingPOST = function (body, authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).createFacilityUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Create or update PCC mapping
     * @param {PccFacilityMappingDto} body facilityMapping
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.createOrUpdateFacilityMappingUsingPUT = function (body, facilityId, authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).createOrUpdateFacilityMappingUsingPUT(body, facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Delete existing facility
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.deleteFacilityUsingDELETE = function (facilityId, authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).deleteFacilityUsingDELETE(facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns found facilities based on query params
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.getFacilitiesUsingGET = function (authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).getFacilitiesUsingGET(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get PCC mapping
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.getFacilityMappingUsingGET = function (facilityId, authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).getFacilityMappingUsingGET(facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Retrieve facility settings for given facility
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.getFacilitySettingsUsingGET = function (facilityId, authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).getFacilitySettingsUsingGET(facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns specific facility
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.getFacilityUsingGET = function (facilityId, authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).getFacilityUsingGET(facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Patch existing facility
     * @param {PatchFacilityDto} body patchFacilityDto
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.patchFacilityUsingPATCH = function (body, facilityId, authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).patchFacilityUsingPATCH(body, facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Persist facility settings
     * @param {any} body settings
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityControllerApi
     */
    FacilityControllerApi.prototype.persistFacilitySettingsUsingPUT = function (body, facilityId, authorization, options) {
        return (0, exports.FacilityControllerApiFp)(this.configuration).persistFacilitySettingsUsingPUT(body, facilityId, authorization, options)(this.fetch, this.basePath);
    };
    return FacilityControllerApi;
}(BaseAPI));
exports.FacilityControllerApi = FacilityControllerApi;
/**
 * FeatureFlagsControllerApi - fetch parameter creator
 * @export
 */
var FeatureFlagsControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Returns true if facility has feature enabled.
         * @param {string} facilityId facilityId
         * @param {string} featureType featureType
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityHasFeatureUsingGET: function (facilityId, featureType, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling facilityHasFeatureUsingGET.');
            }
            // verify required parameter 'featureType' is not null or undefined
            if (featureType === null || featureType === undefined) {
                throw new RequiredError('featureType', 'Required parameter featureType was null or undefined when calling facilityHasFeatureUsingGET.');
            }
            var localVarPath = "/api/v3/feature-flags/facilities/{facilityId}/has-feature/{featureType}"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)))
                .replace("{".concat("featureType", "}"), encodeURIComponent(String(featureType)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of all facility features.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityFeatureFlagsTypesUsingGET: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/v3/feature-flags/facilities/feature-types";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns table of all enabled facility feature flags. Facilities are grouped under their parent organization.
         * @param {ReadonlyArray<string>} [body] body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityFeatureFlagsUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/v3/feature-flags/facilities";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ReadonlyArray&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of all organization flags.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFeatureFlagsTypesUsingGET: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/v3/feature-flags/organizations/feature-types";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of enabled organization flags for each organization.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFeatureFlagsUsingGET: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/v3/feature-flags/organizations";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns true if organization has feature enabled.
         * @param {string} featureType featureType
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationHasFeatureUsingGET: function (featureType, organizationId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'featureType' is not null or undefined
            if (featureType === null || featureType === undefined) {
                throw new RequiredError('featureType', 'Required parameter featureType was null or undefined when calling organizationHasFeatureUsingGET.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling organizationHasFeatureUsingGET.');
            }
            var localVarPath = "/api/v3/feature-flags/organizations/{organizationId}/has-feature/{featureType}"
                .replace("{".concat("featureType", "}"), encodeURIComponent(String(featureType)))
                .replace("{".concat("organizationId", "}"), encodeURIComponent(String(organizationId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Configures enabled facility features.
         * @param {ReadonlyArray<string>} body features
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacilityFeatureFlagUsingPUT: function (body, facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateFacilityFeatureFlagUsingPUT.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling updateFacilityFeatureFlagUsingPUT.');
            }
            var localVarPath = "/api/v3/feature-flags/facilities/{facilityId}"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ReadonlyArray&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Configures enabled organization features.
         * @param {ReadonlyArray<string>} body features
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationFeatureFlagUsingPUT: function (body, organizationId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateOrganizationFeatureFlagUsingPUT.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling updateOrganizationFeatureFlagUsingPUT.');
            }
            var localVarPath = "/api/v3/feature-flags/organizations/{organizationId}"
                .replace("{".concat("organizationId", "}"), encodeURIComponent(String(organizationId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ReadonlyArray&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.FeatureFlagsControllerApiFetchParamCreator = FeatureFlagsControllerApiFetchParamCreator;
/**
 * FeatureFlagsControllerApi - functional programming interface
 * @export
 */
var FeatureFlagsControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Returns true if facility has feature enabled.
         * @param {string} facilityId facilityId
         * @param {string} featureType featureType
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityHasFeatureUsingGET: function (facilityId, featureType, authorization, options) {
            var localVarFetchArgs = (0, exports.FeatureFlagsControllerApiFetchParamCreator)(configuration).facilityHasFeatureUsingGET(facilityId, featureType, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of all facility features.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityFeatureFlagsTypesUsingGET: function (authorization, options) {
            var localVarFetchArgs = (0, exports.FeatureFlagsControllerApiFetchParamCreator)(configuration).getFacilityFeatureFlagsTypesUsingGET(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns table of all enabled facility feature flags. Facilities are grouped under their parent organization.
         * @param {ReadonlyArray<string>} [body] body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityFeatureFlagsUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.FeatureFlagsControllerApiFetchParamCreator)(configuration).getFacilityFeatureFlagsUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of all organization flags.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFeatureFlagsTypesUsingGET: function (authorization, options) {
            var localVarFetchArgs = (0, exports.FeatureFlagsControllerApiFetchParamCreator)(configuration).getOrganizationFeatureFlagsTypesUsingGET(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of enabled organization flags for each organization.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFeatureFlagsUsingGET: function (authorization, options) {
            var localVarFetchArgs = (0, exports.FeatureFlagsControllerApiFetchParamCreator)(configuration).getOrganizationFeatureFlagsUsingGET(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns true if organization has feature enabled.
         * @param {string} featureType featureType
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationHasFeatureUsingGET: function (featureType, organizationId, authorization, options) {
            var localVarFetchArgs = (0, exports.FeatureFlagsControllerApiFetchParamCreator)(configuration).organizationHasFeatureUsingGET(featureType, organizationId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Configures enabled facility features.
         * @param {ReadonlyArray<string>} body features
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacilityFeatureFlagUsingPUT: function (body, facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.FeatureFlagsControllerApiFetchParamCreator)(configuration).updateFacilityFeatureFlagUsingPUT(body, facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Configures enabled organization features.
         * @param {ReadonlyArray<string>} body features
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationFeatureFlagUsingPUT: function (body, organizationId, authorization, options) {
            var localVarFetchArgs = (0, exports.FeatureFlagsControllerApiFetchParamCreator)(configuration).updateOrganizationFeatureFlagUsingPUT(body, organizationId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.FeatureFlagsControllerApiFp = FeatureFlagsControllerApiFp;
/**
 * FeatureFlagsControllerApi - factory interface
 * @export
 */
var FeatureFlagsControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Returns true if facility has feature enabled.
         * @param {string} facilityId facilityId
         * @param {string} featureType featureType
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        facilityHasFeatureUsingGET: function (facilityId, featureType, authorization, options) {
            return (0, exports.FeatureFlagsControllerApiFp)(configuration).facilityHasFeatureUsingGET(facilityId, featureType, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of all facility features.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityFeatureFlagsTypesUsingGET: function (authorization, options) {
            return (0, exports.FeatureFlagsControllerApiFp)(configuration).getFacilityFeatureFlagsTypesUsingGET(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns table of all enabled facility feature flags. Facilities are grouped under their parent organization.
         * @param {ReadonlyArray<string>} [body] body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityFeatureFlagsUsingPOST: function (body, authorization, options) {
            return (0, exports.FeatureFlagsControllerApiFp)(configuration).getFacilityFeatureFlagsUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of all organization flags.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFeatureFlagsTypesUsingGET: function (authorization, options) {
            return (0, exports.FeatureFlagsControllerApiFp)(configuration).getOrganizationFeatureFlagsTypesUsingGET(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of enabled organization flags for each organization.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFeatureFlagsUsingGET: function (authorization, options) {
            return (0, exports.FeatureFlagsControllerApiFp)(configuration).getOrganizationFeatureFlagsUsingGET(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns true if organization has feature enabled.
         * @param {string} featureType featureType
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationHasFeatureUsingGET: function (featureType, organizationId, authorization, options) {
            return (0, exports.FeatureFlagsControllerApiFp)(configuration).organizationHasFeatureUsingGET(featureType, organizationId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Configures enabled facility features.
         * @param {ReadonlyArray<string>} body features
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFacilityFeatureFlagUsingPUT: function (body, facilityId, authorization, options) {
            return (0, exports.FeatureFlagsControllerApiFp)(configuration).updateFacilityFeatureFlagUsingPUT(body, facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Configures enabled organization features.
         * @param {ReadonlyArray<string>} body features
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationFeatureFlagUsingPUT: function (body, organizationId, authorization, options) {
            return (0, exports.FeatureFlagsControllerApiFp)(configuration).updateOrganizationFeatureFlagUsingPUT(body, organizationId, authorization, options)(fetch, basePath);
        },
    };
};
exports.FeatureFlagsControllerApiFactory = FeatureFlagsControllerApiFactory;
/**
 * FeatureFlagsControllerApi - object-oriented interface
 * @export
 * @class FeatureFlagsControllerApi
 * @extends {BaseAPI}
 */
var FeatureFlagsControllerApi = /** @class */ (function (_super) {
    __extends(FeatureFlagsControllerApi, _super);
    function FeatureFlagsControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Returns true if facility has feature enabled.
     * @param {string} facilityId facilityId
     * @param {string} featureType featureType
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsControllerApi
     */
    FeatureFlagsControllerApi.prototype.facilityHasFeatureUsingGET = function (facilityId, featureType, authorization, options) {
        return (0, exports.FeatureFlagsControllerApiFp)(this.configuration).facilityHasFeatureUsingGET(facilityId, featureType, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of all facility features.
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsControllerApi
     */
    FeatureFlagsControllerApi.prototype.getFacilityFeatureFlagsTypesUsingGET = function (authorization, options) {
        return (0, exports.FeatureFlagsControllerApiFp)(this.configuration).getFacilityFeatureFlagsTypesUsingGET(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns table of all enabled facility feature flags. Facilities are grouped under their parent organization.
     * @param {ReadonlyArray<string>} [body] body
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsControllerApi
     */
    FeatureFlagsControllerApi.prototype.getFacilityFeatureFlagsUsingPOST = function (body, authorization, options) {
        return (0, exports.FeatureFlagsControllerApiFp)(this.configuration).getFacilityFeatureFlagsUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of all organization flags.
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsControllerApi
     */
    FeatureFlagsControllerApi.prototype.getOrganizationFeatureFlagsTypesUsingGET = function (authorization, options) {
        return (0, exports.FeatureFlagsControllerApiFp)(this.configuration).getOrganizationFeatureFlagsTypesUsingGET(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of enabled organization flags for each organization.
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsControllerApi
     */
    FeatureFlagsControllerApi.prototype.getOrganizationFeatureFlagsUsingGET = function (authorization, options) {
        return (0, exports.FeatureFlagsControllerApiFp)(this.configuration).getOrganizationFeatureFlagsUsingGET(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns true if organization has feature enabled.
     * @param {string} featureType featureType
     * @param {string} organizationId organizationId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsControllerApi
     */
    FeatureFlagsControllerApi.prototype.organizationHasFeatureUsingGET = function (featureType, organizationId, authorization, options) {
        return (0, exports.FeatureFlagsControllerApiFp)(this.configuration).organizationHasFeatureUsingGET(featureType, organizationId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Configures enabled facility features.
     * @param {ReadonlyArray<string>} body features
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsControllerApi
     */
    FeatureFlagsControllerApi.prototype.updateFacilityFeatureFlagUsingPUT = function (body, facilityId, authorization, options) {
        return (0, exports.FeatureFlagsControllerApiFp)(this.configuration).updateFacilityFeatureFlagUsingPUT(body, facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Configures enabled organization features.
     * @param {ReadonlyArray<string>} body features
     * @param {string} organizationId organizationId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagsControllerApi
     */
    FeatureFlagsControllerApi.prototype.updateOrganizationFeatureFlagUsingPUT = function (body, organizationId, authorization, options) {
        return (0, exports.FeatureFlagsControllerApiFp)(this.configuration).updateOrganizationFeatureFlagUsingPUT(body, organizationId, authorization, options)(this.fetch, this.basePath);
    };
    return FeatureFlagsControllerApi;
}(BaseAPI));
exports.FeatureFlagsControllerApi = FeatureFlagsControllerApi;
/**
 * FileControllerApi - fetch parameter creator
 * @export
 */
var FileControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Download given file by given access token
         * @param {string} accessToken accessToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET: function (accessToken, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken', 'Required parameter accessToken was null or undefined when calling downloadUsingGET.');
            }
            var localVarPath = "/api/files/{accessToken}"
                .replace("{".concat("accessToken", "}"), encodeURIComponent(String(accessToken)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Download given file
         * @param {string} fileId fileId
         * @param {string} [authorization] Authorization Header
         * @param {string} [action] action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET1: function (fileId, authorization, action, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId', 'Required parameter fileId was null or undefined when calling downloadUsingGET1.');
            }
            var localVarPath = "/api/files/{fileId}"
                .replace("{".concat("fileId", "}"), encodeURIComponent(String(fileId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Upload a file
         * @param {Blob} file
         * @param {ModelObject} fileType
         * @param {ModelObject} id
         * @param {ModelObject} name
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFileUsingPOST: function (file, fileType, id, name, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file', 'Required parameter file was null or undefined when calling storeFileUsingPOST.');
            }
            // verify required parameter 'fileType' is not null or undefined
            if (fileType === null || fileType === undefined) {
                throw new RequiredError('fileType', 'Required parameter fileType was null or undefined when calling storeFileUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling storeFileUsingPOST.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name', 'Required parameter name was null or undefined when calling storeFileUsingPOST.');
            }
            var localVarPath = "/api/files";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            var localVarFormParams = new url.URLSearchParams();
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            if (file !== undefined) {
                localVarFormParams.set('file', file);
            }
            if (fileType !== undefined) {
                localVarFormParams.set('fileType', fileType);
            }
            if (id !== undefined) {
                localVarFormParams.set('id', id);
            }
            if (name !== undefined) {
                localVarFormParams.set('name', name);
            }
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.FileControllerApiFetchParamCreator = FileControllerApiFetchParamCreator;
/**
 * FileControllerApi - functional programming interface
 * @export
 */
var FileControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Download given file by given access token
         * @param {string} accessToken accessToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET: function (accessToken, authorization, options) {
            var localVarFetchArgs = (0, exports.FileControllerApiFetchParamCreator)(configuration).downloadUsingGET(accessToken, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Download given file
         * @param {string} fileId fileId
         * @param {string} [authorization] Authorization Header
         * @param {string} [action] action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET1: function (fileId, authorization, action, options) {
            var localVarFetchArgs = (0, exports.FileControllerApiFetchParamCreator)(configuration).downloadUsingGET1(fileId, authorization, action, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Upload a file
         * @param {Blob} file
         * @param {ModelObject} fileType
         * @param {ModelObject} id
         * @param {ModelObject} name
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFileUsingPOST: function (file, fileType, id, name, authorization, options) {
            var localVarFetchArgs = (0, exports.FileControllerApiFetchParamCreator)(configuration).storeFileUsingPOST(file, fileType, id, name, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.FileControllerApiFp = FileControllerApiFp;
/**
 * FileControllerApi - factory interface
 * @export
 */
var FileControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Download given file by given access token
         * @param {string} accessToken accessToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET: function (accessToken, authorization, options) {
            return (0, exports.FileControllerApiFp)(configuration).downloadUsingGET(accessToken, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Download given file
         * @param {string} fileId fileId
         * @param {string} [authorization] Authorization Header
         * @param {string} [action] action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET1: function (fileId, authorization, action, options) {
            return (0, exports.FileControllerApiFp)(configuration).downloadUsingGET1(fileId, authorization, action, options)(fetch, basePath);
        },
        /**
         *
         * @summary Upload a file
         * @param {Blob} file
         * @param {ModelObject} fileType
         * @param {ModelObject} id
         * @param {ModelObject} name
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFileUsingPOST: function (file, fileType, id, name, authorization, options) {
            return (0, exports.FileControllerApiFp)(configuration).storeFileUsingPOST(file, fileType, id, name, authorization, options)(fetch, basePath);
        },
    };
};
exports.FileControllerApiFactory = FileControllerApiFactory;
/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
var FileControllerApi = /** @class */ (function (_super) {
    __extends(FileControllerApi, _super);
    function FileControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Download given file by given access token
     * @param {string} accessToken accessToken
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    FileControllerApi.prototype.downloadUsingGET = function (accessToken, authorization, options) {
        return (0, exports.FileControllerApiFp)(this.configuration).downloadUsingGET(accessToken, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Download given file
     * @param {string} fileId fileId
     * @param {string} [authorization] Authorization Header
     * @param {string} [action] action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    FileControllerApi.prototype.downloadUsingGET1 = function (fileId, authorization, action, options) {
        return (0, exports.FileControllerApiFp)(this.configuration).downloadUsingGET1(fileId, authorization, action, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Upload a file
     * @param {Blob} file
     * @param {ModelObject} fileType
     * @param {ModelObject} id
     * @param {ModelObject} name
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    FileControllerApi.prototype.storeFileUsingPOST = function (file, fileType, id, name, authorization, options) {
        return (0, exports.FileControllerApiFp)(this.configuration).storeFileUsingPOST(file, fileType, id, name, authorization, options)(this.fetch, this.basePath);
    };
    return FileControllerApi;
}(BaseAPI));
exports.FileControllerApi = FileControllerApi;
/**
 * ImportExportControllerApi - fetch parameter creator
 * @export
 */
var ImportExportControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Request user export
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUsersUsingGET: function (organizationId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling exportUsersUsingGET.');
            }
            var localVarPath = "/api/import-export/organizations/{organizationId}/users/export"
                .replace("{".concat("organizationId", "}"), encodeURIComponent(String(organizationId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get csv sample to import users
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCsvSampleUsingGET: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/import-export/users/sample";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get csv template to import users
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCsvTemplateUsingGET: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/import-export/users/template";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Upload users using csv file
         * @param {Blob} file
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUsersUsingPOST: function (file, organizationId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file', 'Required parameter file was null or undefined when calling importUsersUsingPOST.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling importUsersUsingPOST.');
            }
            var localVarPath = "/api/import-export/organizations/{organizationId}/users"
                .replace("{".concat("organizationId", "}"), encodeURIComponent(String(organizationId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            var localVarFormParams = new url.URLSearchParams();
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            if (file !== undefined) {
                localVarFormParams.set('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get user upload history for specific organization
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsersCsvUsingGET: function (organizationId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling uploadUsersCsvUsingGET.');
            }
            var localVarPath = "/api/import-export/organizations/{organizationId}/users"
                .replace("{".concat("organizationId", "}"), encodeURIComponent(String(organizationId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.ImportExportControllerApiFetchParamCreator = ImportExportControllerApiFetchParamCreator;
/**
 * ImportExportControllerApi - functional programming interface
 * @export
 */
var ImportExportControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Request user export
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUsersUsingGET: function (organizationId, authorization, options) {
            var localVarFetchArgs = (0, exports.ImportExportControllerApiFetchParamCreator)(configuration).exportUsersUsingGET(organizationId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get csv sample to import users
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCsvSampleUsingGET: function (authorization, options) {
            var localVarFetchArgs = (0, exports.ImportExportControllerApiFetchParamCreator)(configuration).getUserCsvSampleUsingGET(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get csv template to import users
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCsvTemplateUsingGET: function (authorization, options) {
            var localVarFetchArgs = (0, exports.ImportExportControllerApiFetchParamCreator)(configuration).getUserCsvTemplateUsingGET(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Upload users using csv file
         * @param {Blob} file
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUsersUsingPOST: function (file, organizationId, authorization, options) {
            var localVarFetchArgs = (0, exports.ImportExportControllerApiFetchParamCreator)(configuration).importUsersUsingPOST(file, organizationId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get user upload history for specific organization
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsersCsvUsingGET: function (organizationId, authorization, options) {
            var localVarFetchArgs = (0, exports.ImportExportControllerApiFetchParamCreator)(configuration).uploadUsersCsvUsingGET(organizationId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.ImportExportControllerApiFp = ImportExportControllerApiFp;
/**
 * ImportExportControllerApi - factory interface
 * @export
 */
var ImportExportControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Request user export
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUsersUsingGET: function (organizationId, authorization, options) {
            return (0, exports.ImportExportControllerApiFp)(configuration).exportUsersUsingGET(organizationId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get csv sample to import users
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCsvSampleUsingGET: function (authorization, options) {
            return (0, exports.ImportExportControllerApiFp)(configuration).getUserCsvSampleUsingGET(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get csv template to import users
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCsvTemplateUsingGET: function (authorization, options) {
            return (0, exports.ImportExportControllerApiFp)(configuration).getUserCsvTemplateUsingGET(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Upload users using csv file
         * @param {Blob} file
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUsersUsingPOST: function (file, organizationId, authorization, options) {
            return (0, exports.ImportExportControllerApiFp)(configuration).importUsersUsingPOST(file, organizationId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get user upload history for specific organization
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsersCsvUsingGET: function (organizationId, authorization, options) {
            return (0, exports.ImportExportControllerApiFp)(configuration).uploadUsersCsvUsingGET(organizationId, authorization, options)(fetch, basePath);
        },
    };
};
exports.ImportExportControllerApiFactory = ImportExportControllerApiFactory;
/**
 * ImportExportControllerApi - object-oriented interface
 * @export
 * @class ImportExportControllerApi
 * @extends {BaseAPI}
 */
var ImportExportControllerApi = /** @class */ (function (_super) {
    __extends(ImportExportControllerApi, _super);
    function ImportExportControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Request user export
     * @param {string} organizationId organizationId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportExportControllerApi
     */
    ImportExportControllerApi.prototype.exportUsersUsingGET = function (organizationId, authorization, options) {
        return (0, exports.ImportExportControllerApiFp)(this.configuration).exportUsersUsingGET(organizationId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get csv sample to import users
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportExportControllerApi
     */
    ImportExportControllerApi.prototype.getUserCsvSampleUsingGET = function (authorization, options) {
        return (0, exports.ImportExportControllerApiFp)(this.configuration).getUserCsvSampleUsingGET(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get csv template to import users
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportExportControllerApi
     */
    ImportExportControllerApi.prototype.getUserCsvTemplateUsingGET = function (authorization, options) {
        return (0, exports.ImportExportControllerApiFp)(this.configuration).getUserCsvTemplateUsingGET(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Upload users using csv file
     * @param {Blob} file
     * @param {string} organizationId organizationId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportExportControllerApi
     */
    ImportExportControllerApi.prototype.importUsersUsingPOST = function (file, organizationId, authorization, options) {
        return (0, exports.ImportExportControllerApiFp)(this.configuration).importUsersUsingPOST(file, organizationId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get user upload history for specific organization
     * @param {string} organizationId organizationId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportExportControllerApi
     */
    ImportExportControllerApi.prototype.uploadUsersCsvUsingGET = function (organizationId, authorization, options) {
        return (0, exports.ImportExportControllerApiFp)(this.configuration).uploadUsersCsvUsingGET(organizationId, authorization, options)(this.fetch, this.basePath);
    };
    return ImportExportControllerApi;
}(BaseAPI));
exports.ImportExportControllerApi = ImportExportControllerApi;
/**
 * OrganizationControllerApi - fetch parameter creator
 * @export
 */
var OrganizationControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create a new organization
         * @param {CreateOrganizationDto} body createOrganizationDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createOrganizationUsingPOST.');
            }
            var localVarPath = "/api/organizations";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("CreateOrganizationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns all active facilities of the organization
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {boolean} [sorted]
         * @param {boolean} [unsorted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFacilitiesUsingGET: function (organizationId, authorization, sorted, unsorted, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling getOrganizationFacilitiesUsingGET.');
            }
            var localVarPath = "/api/organizations/{organizationId}/facilities"
                .replace("{".concat("organizationId", "}"), encodeURIComponent(String(organizationId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (sorted !== undefined) {
                localVarQueryParameter['sorted'] = sorted;
            }
            if (unsorted !== undefined) {
                localVarQueryParameter['unsorted'] = unsorted;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns organization by id
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsingGET: function (organizationId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling getOrganizationUsingGET.');
            }
            var localVarPath = "/api/organizations/{organizationId}"
                .replace("{".concat("organizationId", "}"), encodeURIComponent(String(organizationId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns organizations by search params
         * @param {ModelObject} allRequestParams allRequestParams
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsUsingGET: function (allRequestParams, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'allRequestParams' is not null or undefined
            if (allRequestParams === null || allRequestParams === undefined) {
                throw new RequiredError('allRequestParams', 'Required parameter allRequestParams was null or undefined when calling getOrganizationsUsingGET.');
            }
            var localVarPath = "/api/organizations";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (allRequestParams !== undefined) {
                localVarQueryParameter['allRequestParams'] = allRequestParams;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update existing organization
         * @param {UpdateOrganizationDto} body updateOrganizationDto
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUsingPUT: function (body, organizationId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateOrganizationUsingPUT.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId', 'Required parameter organizationId was null or undefined when calling updateOrganizationUsingPUT.');
            }
            var localVarPath = "/api/organizations/{organizationId}"
                .replace("{".concat("organizationId", "}"), encodeURIComponent(String(organizationId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("UpdateOrganizationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.OrganizationControllerApiFetchParamCreator = OrganizationControllerApiFetchParamCreator;
/**
 * OrganizationControllerApi - functional programming interface
 * @export
 */
var OrganizationControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create a new organization
         * @param {CreateOrganizationDto} body createOrganizationDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.OrganizationControllerApiFetchParamCreator)(configuration).createOrganizationUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns all active facilities of the organization
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {boolean} [sorted]
         * @param {boolean} [unsorted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFacilitiesUsingGET: function (organizationId, authorization, sorted, unsorted, options) {
            var localVarFetchArgs = (0, exports.OrganizationControllerApiFetchParamCreator)(configuration).getOrganizationFacilitiesUsingGET(organizationId, authorization, sorted, unsorted, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns organization by id
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsingGET: function (organizationId, authorization, options) {
            var localVarFetchArgs = (0, exports.OrganizationControllerApiFetchParamCreator)(configuration).getOrganizationUsingGET(organizationId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns organizations by search params
         * @param {ModelObject} allRequestParams allRequestParams
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsUsingGET: function (allRequestParams, authorization, options) {
            var localVarFetchArgs = (0, exports.OrganizationControllerApiFetchParamCreator)(configuration).getOrganizationsUsingGET(allRequestParams, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Update existing organization
         * @param {UpdateOrganizationDto} body updateOrganizationDto
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUsingPUT: function (body, organizationId, authorization, options) {
            var localVarFetchArgs = (0, exports.OrganizationControllerApiFetchParamCreator)(configuration).updateOrganizationUsingPUT(body, organizationId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.OrganizationControllerApiFp = OrganizationControllerApiFp;
/**
 * OrganizationControllerApi - factory interface
 * @export
 */
var OrganizationControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Create a new organization
         * @param {CreateOrganizationDto} body createOrganizationDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationUsingPOST: function (body, authorization, options) {
            return (0, exports.OrganizationControllerApiFp)(configuration).createOrganizationUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns all active facilities of the organization
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {boolean} [sorted]
         * @param {boolean} [unsorted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFacilitiesUsingGET: function (organizationId, authorization, sorted, unsorted, options) {
            return (0, exports.OrganizationControllerApiFp)(configuration).getOrganizationFacilitiesUsingGET(organizationId, authorization, sorted, unsorted, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns organization by id
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsingGET: function (organizationId, authorization, options) {
            return (0, exports.OrganizationControllerApiFp)(configuration).getOrganizationUsingGET(organizationId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns organizations by search params
         * @param {ModelObject} allRequestParams allRequestParams
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsUsingGET: function (allRequestParams, authorization, options) {
            return (0, exports.OrganizationControllerApiFp)(configuration).getOrganizationsUsingGET(allRequestParams, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Update existing organization
         * @param {UpdateOrganizationDto} body updateOrganizationDto
         * @param {string} organizationId organizationId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUsingPUT: function (body, organizationId, authorization, options) {
            return (0, exports.OrganizationControllerApiFp)(configuration).updateOrganizationUsingPUT(body, organizationId, authorization, options)(fetch, basePath);
        },
    };
};
exports.OrganizationControllerApiFactory = OrganizationControllerApiFactory;
/**
 * OrganizationControllerApi - object-oriented interface
 * @export
 * @class OrganizationControllerApi
 * @extends {BaseAPI}
 */
var OrganizationControllerApi = /** @class */ (function (_super) {
    __extends(OrganizationControllerApi, _super);
    function OrganizationControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create a new organization
     * @param {CreateOrganizationDto} body createOrganizationDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.createOrganizationUsingPOST = function (body, authorization, options) {
        return (0, exports.OrganizationControllerApiFp)(this.configuration).createOrganizationUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns all active facilities of the organization
     * @param {string} organizationId organizationId
     * @param {string} [authorization] Authorization Header
     * @param {boolean} [sorted]
     * @param {boolean} [unsorted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.getOrganizationFacilitiesUsingGET = function (organizationId, authorization, sorted, unsorted, options) {
        return (0, exports.OrganizationControllerApiFp)(this.configuration).getOrganizationFacilitiesUsingGET(organizationId, authorization, sorted, unsorted, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns organization by id
     * @param {string} organizationId organizationId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.getOrganizationUsingGET = function (organizationId, authorization, options) {
        return (0, exports.OrganizationControllerApiFp)(this.configuration).getOrganizationUsingGET(organizationId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns organizations by search params
     * @param {ModelObject} allRequestParams allRequestParams
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.getOrganizationsUsingGET = function (allRequestParams, authorization, options) {
        return (0, exports.OrganizationControllerApiFp)(this.configuration).getOrganizationsUsingGET(allRequestParams, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Update existing organization
     * @param {UpdateOrganizationDto} body updateOrganizationDto
     * @param {string} organizationId organizationId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    OrganizationControllerApi.prototype.updateOrganizationUsingPUT = function (body, organizationId, authorization, options) {
        return (0, exports.OrganizationControllerApiFp)(this.configuration).updateOrganizationUsingPUT(body, organizationId, authorization, options)(this.fetch, this.basePath);
    };
    return OrganizationControllerApi;
}(BaseAPI));
exports.OrganizationControllerApi = OrganizationControllerApi;
/**
 * UserControllerApi - fetch parameter creator
 * @export
 */
var UserControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Accept reset password request based on token
         * @param {ChangePasswordDto} body resetPasswordDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeForgottenPasswordUsingPUT: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling changeForgottenPasswordUsingPUT.');
            }
            var localVarPath = "/api/users/change-password";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ChangePasswordDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Accept reset password request based on old password
         * @param {ChangeUserPasswordDto} body resetPasswordDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPasswordUsingPUT: function (body, userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling changeUserPasswordUsingPUT.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling changeUserPasswordUsingPUT.');
            }
            var localVarPath = "/api/users/{userId}/change-user-password"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ChangeUserPasswordDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create new staff user - clerk/facility admin/organization admin/compliance user/reside admin
         * @param {CreateStaffUserDto} body createStaffUserDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStaffUserUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createStaffUserUsingPOST.');
            }
            var localVarPath = "/api/users";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("CreateStaffUserDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary soft delete user
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStaffUserUsingDELETE: function (userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling deleteStaffUserUsingDELETE.');
            }
            var localVarPath = "/api/users/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Fetch users within the scope of authenticated user
         * @param {ParametersDto} body body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUsersUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling fetchUsersUsingPOST.');
            }
            var localVarPath = "/api/users/fetch";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ParametersDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of all available permissions of given type.
         * @param {string} type type
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET: function (type, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling getUserPermissionsUsingGET.');
            }
            var localVarPath = "/api/users/reporting-permissions/{type}"
                .replace("{".concat("type", "}"), encodeURIComponent(String(type)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of all active permissions for logged user.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET1: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/users/reporting-permissions";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of all active permissions for given userId.
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET2: function (userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserPermissionsUsingGET2.');
            }
            var localVarPath = "/api/users/{userId}/reporting-permissions"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returning single user
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET: function (userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserUsingGET.');
            }
            var localVarPath = "/api/users/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Query users within the scope of authenticated user
         * @param {string} query query
         * @param {string} [authorization] Authorization Header
         * @param {ReadonlyArray<string>} [facilityIds] facilityIds
         * @param {ReadonlyArray<string>} [roles] roles
         * @param {ReadonlyArray<string>} [statuses] statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUsersUsingGET: function (query, authorization, facilityIds, roles, statuses, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query', 'Required parameter query was null or undefined when calling queryUsersUsingGET.');
            }
            var localVarPath = "/api/users/query";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (facilityIds) {
                localVarQueryParameter['facilityIds'] = facilityIds;
            }
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }
            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Resend activation email
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendActivationEmailUsingPUT: function (userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling resendActivationEmailUsingPUT.');
            }
            var localVarPath = "/api/users/{userId}/resend-activation-email"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Send an email to verify, generate token and send an appropriate email with reset link
         * @param {CreateResetPasswordDto} body createResetPasswordDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPUT: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling resetPasswordUsingPUT.');
            }
            var localVarPath = "/api/users/reset-password";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("CreateResetPasswordDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update profile properties (first name, last name, email, phone) of the user
         * @param {ProfileUserDto} body profileUserDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileUserUsingPATCH: function (body, userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateProfileUserUsingPATCH.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateProfileUserUsingPATCH.');
            }
            var localVarPath = "/api/users/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ProfileUserDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Put reporting permissions
         * @param {ReadonlyArray<string>} body permissions
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportingPermissionsUsingPUT: function (body, userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateReportingPermissionsUsingPUT.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateReportingPermissionsUsingPUT.');
            }
            var localVarPath = "/api/users/{userId}/reporting-permissions"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ReadonlyArray&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update basic user info
         * @param {UpdateStaffDto} body updateStaffDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStaffUserUsingPUT: function (body, userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateStaffUserUsingPUT.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling updateStaffUserUsingPUT.');
            }
            var localVarPath = "/api/users/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("UpdateStaffDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Verify account and initialize user's credentials
         * @param {VerificationUserDto} body verificationUserDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAccountUsingPUT: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling verifyAccountUsingPUT.');
            }
            var localVarPath = "/api/users/verify-account";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("VerificationUserDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.UserControllerApiFetchParamCreator = UserControllerApiFetchParamCreator;
/**
 * UserControllerApi - functional programming interface
 * @export
 */
var UserControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Accept reset password request based on token
         * @param {ChangePasswordDto} body resetPasswordDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeForgottenPasswordUsingPUT: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).changeForgottenPasswordUsingPUT(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Accept reset password request based on old password
         * @param {ChangeUserPasswordDto} body resetPasswordDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPasswordUsingPUT: function (body, userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).changeUserPasswordUsingPUT(body, userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Create new staff user - clerk/facility admin/organization admin/compliance user/reside admin
         * @param {CreateStaffUserDto} body createStaffUserDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStaffUserUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).createStaffUserUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary soft delete user
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStaffUserUsingDELETE: function (userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).deleteStaffUserUsingDELETE(userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Fetch users within the scope of authenticated user
         * @param {ParametersDto} body body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUsersUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).fetchUsersUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of all available permissions of given type.
         * @param {string} type type
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET: function (type, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).getUserPermissionsUsingGET(type, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of all active permissions for logged user.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET1: function (authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).getUserPermissionsUsingGET1(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of all active permissions for given userId.
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET2: function (userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).getUserPermissionsUsingGET2(userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returning single user
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET: function (userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).getUserUsingGET(userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Query users within the scope of authenticated user
         * @param {string} query query
         * @param {string} [authorization] Authorization Header
         * @param {ReadonlyArray<string>} [facilityIds] facilityIds
         * @param {ReadonlyArray<string>} [roles] roles
         * @param {ReadonlyArray<string>} [statuses] statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUsersUsingGET: function (query, authorization, facilityIds, roles, statuses, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).queryUsersUsingGET(query, authorization, facilityIds, roles, statuses, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Resend activation email
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendActivationEmailUsingPUT: function (userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).resendActivationEmailUsingPUT(userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Send an email to verify, generate token and send an appropriate email with reset link
         * @param {CreateResetPasswordDto} body createResetPasswordDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPUT: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).resetPasswordUsingPUT(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Update profile properties (first name, last name, email, phone) of the user
         * @param {ProfileUserDto} body profileUserDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileUserUsingPATCH: function (body, userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).updateProfileUserUsingPATCH(body, userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Put reporting permissions
         * @param {ReadonlyArray<string>} body permissions
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportingPermissionsUsingPUT: function (body, userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).updateReportingPermissionsUsingPUT(body, userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Update basic user info
         * @param {UpdateStaffDto} body updateStaffDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStaffUserUsingPUT: function (body, userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).updateStaffUserUsingPUT(body, userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Verify account and initialize user's credentials
         * @param {VerificationUserDto} body verificationUserDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAccountUsingPUT: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.UserControllerApiFetchParamCreator)(configuration).verifyAccountUsingPUT(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.UserControllerApiFp = UserControllerApiFp;
/**
 * UserControllerApi - factory interface
 * @export
 */
var UserControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Accept reset password request based on token
         * @param {ChangePasswordDto} body resetPasswordDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeForgottenPasswordUsingPUT: function (body, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).changeForgottenPasswordUsingPUT(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Accept reset password request based on old password
         * @param {ChangeUserPasswordDto} body resetPasswordDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPasswordUsingPUT: function (body, userId, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).changeUserPasswordUsingPUT(body, userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Create new staff user - clerk/facility admin/organization admin/compliance user/reside admin
         * @param {CreateStaffUserDto} body createStaffUserDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStaffUserUsingPOST: function (body, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).createStaffUserUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary soft delete user
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStaffUserUsingDELETE: function (userId, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).deleteStaffUserUsingDELETE(userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Fetch users within the scope of authenticated user
         * @param {ParametersDto} body body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUsersUsingPOST: function (body, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).fetchUsersUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of all available permissions of given type.
         * @param {string} type type
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET: function (type, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).getUserPermissionsUsingGET(type, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of all active permissions for logged user.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET1: function (authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).getUserPermissionsUsingGET1(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of all active permissions for given userId.
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET2: function (userId, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).getUserPermissionsUsingGET2(userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returning single user
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET: function (userId, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).getUserUsingGET(userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Query users within the scope of authenticated user
         * @param {string} query query
         * @param {string} [authorization] Authorization Header
         * @param {ReadonlyArray<string>} [facilityIds] facilityIds
         * @param {ReadonlyArray<string>} [roles] roles
         * @param {ReadonlyArray<string>} [statuses] statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUsersUsingGET: function (query, authorization, facilityIds, roles, statuses, options) {
            return (0, exports.UserControllerApiFp)(configuration).queryUsersUsingGET(query, authorization, facilityIds, roles, statuses, options)(fetch, basePath);
        },
        /**
         *
         * @summary Resend activation email
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendActivationEmailUsingPUT: function (userId, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).resendActivationEmailUsingPUT(userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Send an email to verify, generate token and send an appropriate email with reset link
         * @param {CreateResetPasswordDto} body createResetPasswordDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPUT: function (body, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).resetPasswordUsingPUT(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Update profile properties (first name, last name, email, phone) of the user
         * @param {ProfileUserDto} body profileUserDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileUserUsingPATCH: function (body, userId, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).updateProfileUserUsingPATCH(body, userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Put reporting permissions
         * @param {ReadonlyArray<string>} body permissions
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportingPermissionsUsingPUT: function (body, userId, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).updateReportingPermissionsUsingPUT(body, userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Update basic user info
         * @param {UpdateStaffDto} body updateStaffDto
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStaffUserUsingPUT: function (body, userId, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).updateStaffUserUsingPUT(body, userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Verify account and initialize user's credentials
         * @param {VerificationUserDto} body verificationUserDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAccountUsingPUT: function (body, authorization, options) {
            return (0, exports.UserControllerApiFp)(configuration).verifyAccountUsingPUT(body, authorization, options)(fetch, basePath);
        },
    };
};
exports.UserControllerApiFactory = UserControllerApiFactory;
/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
var UserControllerApi = /** @class */ (function (_super) {
    __extends(UserControllerApi, _super);
    function UserControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Accept reset password request based on token
     * @param {ChangePasswordDto} body resetPasswordDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.changeForgottenPasswordUsingPUT = function (body, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).changeForgottenPasswordUsingPUT(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Accept reset password request based on old password
     * @param {ChangeUserPasswordDto} body resetPasswordDto
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.changeUserPasswordUsingPUT = function (body, userId, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).changeUserPasswordUsingPUT(body, userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Create new staff user - clerk/facility admin/organization admin/compliance user/reside admin
     * @param {CreateStaffUserDto} body createStaffUserDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.createStaffUserUsingPOST = function (body, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).createStaffUserUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary soft delete user
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.deleteStaffUserUsingDELETE = function (userId, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).deleteStaffUserUsingDELETE(userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Fetch users within the scope of authenticated user
     * @param {ParametersDto} body body
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.fetchUsersUsingPOST = function (body, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).fetchUsersUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of all available permissions of given type.
     * @param {string} type type
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.getUserPermissionsUsingGET = function (type, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).getUserPermissionsUsingGET(type, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of all active permissions for logged user.
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.getUserPermissionsUsingGET1 = function (authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).getUserPermissionsUsingGET1(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of all active permissions for given userId.
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.getUserPermissionsUsingGET2 = function (userId, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).getUserPermissionsUsingGET2(userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returning single user
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.getUserUsingGET = function (userId, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).getUserUsingGET(userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Query users within the scope of authenticated user
     * @param {string} query query
     * @param {string} [authorization] Authorization Header
     * @param {ReadonlyArray<string>} [facilityIds] facilityIds
     * @param {ReadonlyArray<string>} [roles] roles
     * @param {ReadonlyArray<string>} [statuses] statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.queryUsersUsingGET = function (query, authorization, facilityIds, roles, statuses, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).queryUsersUsingGET(query, authorization, facilityIds, roles, statuses, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Resend activation email
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.resendActivationEmailUsingPUT = function (userId, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).resendActivationEmailUsingPUT(userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Send an email to verify, generate token and send an appropriate email with reset link
     * @param {CreateResetPasswordDto} body createResetPasswordDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.resetPasswordUsingPUT = function (body, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).resetPasswordUsingPUT(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Update profile properties (first name, last name, email, phone) of the user
     * @param {ProfileUserDto} body profileUserDto
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.updateProfileUserUsingPATCH = function (body, userId, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).updateProfileUserUsingPATCH(body, userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Put reporting permissions
     * @param {ReadonlyArray<string>} body permissions
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.updateReportingPermissionsUsingPUT = function (body, userId, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).updateReportingPermissionsUsingPUT(body, userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Update basic user info
     * @param {UpdateStaffDto} body updateStaffDto
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.updateStaffUserUsingPUT = function (body, userId, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).updateStaffUserUsingPUT(body, userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Verify account and initialize user's credentials
     * @param {VerificationUserDto} body verificationUserDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    UserControllerApi.prototype.verifyAccountUsingPUT = function (body, authorization, options) {
        return (0, exports.UserControllerApiFp)(this.configuration).verifyAccountUsingPUT(body, authorization, options)(this.fetch, this.basePath);
    };
    return UserControllerApi;
}(BaseAPI));
exports.UserControllerApi = UserControllerApi;
/**
 * UserPermissionControllerApi - fetch parameter creator
 * @export
 */
var UserPermissionControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Deny permission to user
         * @param {string} permissionName permissionName
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        denyPermissionUsingPOST: function (permissionName, userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'permissionName' is not null or undefined
            if (permissionName === null || permissionName === undefined) {
                throw new RequiredError('permissionName', 'Required parameter permissionName was null or undefined when calling denyPermissionUsingPOST.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling denyPermissionUsingPOST.');
            }
            var localVarPath = "/api/user-permissions/deny";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (permissionName !== undefined) {
                localVarQueryParameter['permissionName'] = permissionName;
            }
            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of all active permissions for logged user.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET3: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/user-permissions/";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of all active permissions for given userId.
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET4: function (userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserPermissionsUsingGET4.');
            }
            var localVarPath = "/api/user-permissions/user/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of all available permissions of given type.
         * @param {string} type type
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET5: function (type, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling getUserPermissionsUsingGET5.');
            }
            var localVarPath = "/api/user-permissions/{type}"
                .replace("{".concat("type", "}"), encodeURIComponent(String(type)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Grant permission to user
         * @param {string} permissionName permissionName
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPermissionUsingPOST: function (permissionName, userId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'permissionName' is not null or undefined
            if (permissionName === null || permissionName === undefined) {
                throw new RequiredError('permissionName', 'Required parameter permissionName was null or undefined when calling grantPermissionUsingPOST.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling grantPermissionUsingPOST.');
            }
            var localVarPath = "/api/user-permissions/grant";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (permissionName !== undefined) {
                localVarQueryParameter['permissionName'] = permissionName;
            }
            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.UserPermissionControllerApiFetchParamCreator = UserPermissionControllerApiFetchParamCreator;
/**
 * UserPermissionControllerApi - functional programming interface
 * @export
 */
var UserPermissionControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Deny permission to user
         * @param {string} permissionName permissionName
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        denyPermissionUsingPOST: function (permissionName, userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserPermissionControllerApiFetchParamCreator)(configuration).denyPermissionUsingPOST(permissionName, userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of all active permissions for logged user.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET3: function (authorization, options) {
            var localVarFetchArgs = (0, exports.UserPermissionControllerApiFetchParamCreator)(configuration).getUserPermissionsUsingGET3(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of all active permissions for given userId.
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET4: function (userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserPermissionControllerApiFetchParamCreator)(configuration).getUserPermissionsUsingGET4(userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of all available permissions of given type.
         * @param {string} type type
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET5: function (type, authorization, options) {
            var localVarFetchArgs = (0, exports.UserPermissionControllerApiFetchParamCreator)(configuration).getUserPermissionsUsingGET5(type, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Grant permission to user
         * @param {string} permissionName permissionName
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPermissionUsingPOST: function (permissionName, userId, authorization, options) {
            var localVarFetchArgs = (0, exports.UserPermissionControllerApiFetchParamCreator)(configuration).grantPermissionUsingPOST(permissionName, userId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.UserPermissionControllerApiFp = UserPermissionControllerApiFp;
/**
 * UserPermissionControllerApi - factory interface
 * @export
 */
var UserPermissionControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Deny permission to user
         * @param {string} permissionName permissionName
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        denyPermissionUsingPOST: function (permissionName, userId, authorization, options) {
            return (0, exports.UserPermissionControllerApiFp)(configuration).denyPermissionUsingPOST(permissionName, userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of all active permissions for logged user.
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET3: function (authorization, options) {
            return (0, exports.UserPermissionControllerApiFp)(configuration).getUserPermissionsUsingGET3(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of all active permissions for given userId.
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET4: function (userId, authorization, options) {
            return (0, exports.UserPermissionControllerApiFp)(configuration).getUserPermissionsUsingGET4(userId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of all available permissions of given type.
         * @param {string} type type
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPermissionsUsingGET5: function (type, authorization, options) {
            return (0, exports.UserPermissionControllerApiFp)(configuration).getUserPermissionsUsingGET5(type, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Grant permission to user
         * @param {string} permissionName permissionName
         * @param {string} userId userId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPermissionUsingPOST: function (permissionName, userId, authorization, options) {
            return (0, exports.UserPermissionControllerApiFp)(configuration).grantPermissionUsingPOST(permissionName, userId, authorization, options)(fetch, basePath);
        },
    };
};
exports.UserPermissionControllerApiFactory = UserPermissionControllerApiFactory;
/**
 * UserPermissionControllerApi - object-oriented interface
 * @export
 * @class UserPermissionControllerApi
 * @extends {BaseAPI}
 */
var UserPermissionControllerApi = /** @class */ (function (_super) {
    __extends(UserPermissionControllerApi, _super);
    function UserPermissionControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Deny permission to user
     * @param {string} permissionName permissionName
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPermissionControllerApi
     */
    UserPermissionControllerApi.prototype.denyPermissionUsingPOST = function (permissionName, userId, authorization, options) {
        return (0, exports.UserPermissionControllerApiFp)(this.configuration).denyPermissionUsingPOST(permissionName, userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of all active permissions for logged user.
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPermissionControllerApi
     */
    UserPermissionControllerApi.prototype.getUserPermissionsUsingGET3 = function (authorization, options) {
        return (0, exports.UserPermissionControllerApiFp)(this.configuration).getUserPermissionsUsingGET3(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of all active permissions for given userId.
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPermissionControllerApi
     */
    UserPermissionControllerApi.prototype.getUserPermissionsUsingGET4 = function (userId, authorization, options) {
        return (0, exports.UserPermissionControllerApiFp)(this.configuration).getUserPermissionsUsingGET4(userId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of all available permissions of given type.
     * @param {string} type type
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPermissionControllerApi
     */
    UserPermissionControllerApi.prototype.getUserPermissionsUsingGET5 = function (type, authorization, options) {
        return (0, exports.UserPermissionControllerApiFp)(this.configuration).getUserPermissionsUsingGET5(type, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Grant permission to user
     * @param {string} permissionName permissionName
     * @param {string} userId userId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPermissionControllerApi
     */
    UserPermissionControllerApi.prototype.grantPermissionUsingPOST = function (permissionName, userId, authorization, options) {
        return (0, exports.UserPermissionControllerApiFp)(this.configuration).grantPermissionUsingPOST(permissionName, userId, authorization, options)(this.fetch, this.basePath);
    };
    return UserPermissionControllerApi;
}(BaseAPI));
exports.UserPermissionControllerApi = UserPermissionControllerApi;
