import {ExternalLink, styled} from "@reside/ui";

export const ExternalLinkStyled = styled(ExternalLink)`
  text-decoration: none;
  display: block;
  width: fit-content;
`;

export const Footer = styled.div`
  margin-top: ${({theme}) => `${theme.space[4]}px`};
  margin-bottom: ${({theme}) => `${theme.space[5]}px`};
  display: flex;
  gap: 16px;
`;
