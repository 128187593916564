import React from "react";
import {useFormikContext} from "formik";
import {Button} from "@reside/ui";

import {useFormikIsFieldDirty} from "../../../../../hooks/useFormikIsFieldDirty";
import {useFetchFacilitiesQuery} from "../../../../../routers/AdminAppRefresh";
import {FacilityPccMappingFormValues} from "./types";
import {Footer} from "./styles";

/**
 * Component that renders the form buttons for PCC Facility Mapping settings.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} props.isPccFacilityMappingUpdating - Indicates whether the PCC Facility Mapping is currently being updated.
 * @returns {JSX.Element} JSX element representing the form buttons.
 *
 * @see {@link useFormikIsFieldDirty} for more details on the dirty input check logic.
 *
 */
export const FormButtons = ({
  isPccFacilityMappingUpdating,
  isFormValid,
}: {
  isPccFacilityMappingUpdating: boolean;
  isFormValid: boolean;
}): JSX.Element => {
  const {isInputDirty} = useFormikIsFieldDirty();

  const {values, submitForm, setFieldValue, validateForm} =
    useFormikContext<FacilityPccMappingFormValues>();

  const {isFetchingFacilities} = useFetchFacilitiesQuery();

  // Conditions for disabling form buttons
  const valuesInFormAreUnchanged =
    !isInputDirty("pccFacilityId") &&
    !isInputDirty("pccOrganizationId") &&
    !isInputDirty("defaultDocumentCategoryIdentifier");

  const bothPccIdAndOrganizationIdFieldsAreEmpty =
    !values.pccFacilityId && !values.pccOrganizationId;

  const anyPccChecboxIsChecked = Boolean(values.pccFeatureFlags.length);

  return (
    <Footer>
      <Button
        color="success"
        testId="pccSettingsBtn"
        disabled={
          !isFormValid ||
          valuesInFormAreUnchanged ||
          isPccFacilityMappingUpdating
        }
        onClick={submitForm}
      >
        Save
      </Button>

      <Button
        color="primary"
        outline
        testId="pccSettingsBtn"
        disabled={
          isPccFacilityMappingUpdating ||
          bothPccIdAndOrganizationIdFieldsAreEmpty ||
          anyPccChecboxIsChecked ||
          isFetchingFacilities
        }
        onClick={() => {
          // Clear values
          setFieldValue("pccOrganizationId", "");
          setFieldValue("pccFacilityId", "");

          // Re-validate form to enable save button
          validateForm();
        }}
      >
        Clear PCC ID fields
      </Button>
    </Footer>
  );
};
