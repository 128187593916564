import {useFormikContext} from "formik";
import {isEqual} from "lodash";

/**
 * Custom hook to determine if a specific field in Formik is dirty (i.e., has been modified).
 *
 * At the moment of writing, Formik did not have the ability to check the dirty state of individual fields.
 * Additionally, the dirty state extracted from Formik remains dirty when a value is returned to its initial state.
 * This hook serves as a workaround for these limitations.
 *
 * @see https://github.com/jaredpalmer/formik/issues/3612 for more details.
 *
 * @returns {Function} isInputDirty - Function to check if the specified field is dirty.
 *
 * @example
 * const isInputDirty = useFormikIsFieldDirty();
 *
 * if (isInputDirty('pccFacilityId')) {
 *   // Do something when the field is dirty
 * }
 *
 */
export function useFormikIsFieldDirty() {
  const {getFieldMeta} = useFormikContext();

  /**
   * Checks if a specific field in the form is dirty.
   *
   * @param {string} name - The name of the field to check.
   * @returns {boolean} - Returns `true` if the field is dirty, `false` otherwise.
   *
   */
  const isInputDirty = (name: string) => {
    // If the field is an array, deep check if the elements have changed
    if (Array.isArray(getFieldMeta(name).value)) {
      return !isEqual(
        getFieldMeta(name).value,
        getFieldMeta(name).initialValue,
      );
    }

    // Avoid strict equality when initial value is number, but becomes string on change.
    // eslint-disable-next-line eqeqeq
    return getFieldMeta(name).value != getFieldMeta(name).initialValue;
  };

  return {isInputDirty};
}
