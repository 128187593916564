import {connect} from "react-redux";
import {AppState} from "../../store/store";
import {FeatureFlagsTable} from "./atoms/";
import {select} from "../../store";
import {BackdropSpinner} from "../../atoms/spinner";
import {
  useFetchFeatureFlagsByFacilities,
  useFetchAllFacilityFeatureFlags,
  useUpdateFacilityFeatureFlags,
} from "./hooks";
import {useFetchFacilitiesQuery} from "../../routers/AdminAppRefresh";

type Props = Readonly<{
  activeFacilitiesIds: ReadonlyArray<string>;
  hasSelectedFacilities: boolean;
  isLoading: boolean;
}>;

const Renderer = ({
  activeFacilitiesIds,
  hasSelectedFacilities,
  isLoading,
}: Props) => {
  const {data: allFeatureFlags, isFetching: isFetchingFeatureFlags} =
    useFetchAllFacilityFeatureFlags();

  const {data, isFetching, refetch} = useFetchFeatureFlagsByFacilities({
    facilityIds: activeFacilitiesIds,
  });

  const {fetchFacilitiesQuery} = useFetchFacilitiesQuery();

  const {mutate: updateFlags, isLoading: isSaving} =
    useUpdateFacilityFeatureFlags();

  const onChange = ({
    id,
    featureTypes,
  }: {
    id: string;
    featureTypes: ReadonlyArray<string>;
  }) => {
    updateFlags({
      featureTypes,
      id,
      onSettled: refetch,
      onSuccess: () => fetchFacilitiesQuery(),
    });
  };

  return (
    <BackdropSpinner
      active={isFetchingFeatureFlags || isFetching || isSaving || isLoading}
    >
      <FeatureFlagsTable
        title="Feature Flags"
        subtitle="By Facilities"
        allFeatureFlags={
          allFeatureFlags?.filter(
            // Don't show PCC feature flags because they are handled separately in facility settings page
            featureFlag => !featureFlag.includes("PCC"),
          ) ?? []
        }
        data={hasSelectedFacilities ? data : []}
        header="Facility"
        onChange={onChange}
      />
    </BackdropSpinner>
  );
};

const mapState = (state: AppState & {loading: any}) => {
  const props: Props = {
    activeFacilitiesIds: select.adminSession.activeFacilitiesIds(state),
    hasSelectedFacilities: select.adminSession.hasSelectedFacilities(state),
    isLoading: state.loading.effects.adminSession.refreshFacilities,
  };

  return props;
};

export const PageAdminFeatureFlagsByFacilities = connect(mapState)(Renderer);
