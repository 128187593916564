"use strict";
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Integrations API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.0.1
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationsServiceApi = exports.IntegrationsServiceApiFactory = exports.IntegrationsServiceApiFp = exports.IntegrationsServiceApiFetchParamCreator = exports.RequiredError = exports.BaseAPI = exports.COLLECTION_FORMATS = void 0;
var url = __importStar(require("url"));
var portableFetch = __importStar(require("portable-fetch"));
var BASE_PATH = "https://develop-api.resideadmissions.com/".replace(/\/+$/, "");
/**
 *
 * @export
 */
exports.COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration, basePath, fetch) {
        if (basePath === void 0) { basePath = BASE_PATH; }
        if (fetch === void 0) { fetch = portableFetch; }
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
    return BaseAPI;
}());
exports.BaseAPI = BaseAPI;
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        return _this;
    }
    return RequiredError;
}(Error));
exports.RequiredError = RequiredError;
/**
 * IntegrationsServiceApi - fetch parameter creator
 * @export
 */
var IntegrationsServiceApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Removes all QuickSight cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceDeleteQuickSightCache: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/integrations/quicksight/cache";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves all visible dashboards from quicksight
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetAdminDashboards: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/integrations/quicksight/admin/dashboards";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns an embedded url which renders a dashboard
         * @param {string} id the dashboard_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboardURL: function (id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling integrationsServiceGetDashboardURL.');
            }
            var localVarPath = "/api/integrations/quicksight/dashboards/{id}"
                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrives the dashboards the connected user can access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboards: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/integrations/quicksight/dashboards";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the dashboards visible for one given user
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboardsUser: function (userId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling integrationsServiceGetDashboardsUser.');
            }
            var localVarPath = "/api/integrations/quicksight/admin/dashboards/{userId}"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Checks service is up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetHealth: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/integrations/health";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Grants and revokes permissions to a dashboard for a given user
         * @param {IntegrationsPostDashboardInput} body
         * @param {string} userId UserID is the the user identifier en reside
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServicePostDashboard: function (body, userId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling integrationsServicePostDashboard.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling integrationsServicePostDashboard.');
            }
            var localVarPath = "/api/integrations/quicksight/users/{userId}/dashboards"
                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearer required
            if (configuration && configuration.apiKey) {
                var localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("IntegrationsPostDashboardInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.IntegrationsServiceApiFetchParamCreator = IntegrationsServiceApiFetchParamCreator;
/**
 * IntegrationsServiceApi - functional programming interface
 * @export
 */
var IntegrationsServiceApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Removes all QuickSight cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceDeleteQuickSightCache: function (options) {
            var localVarFetchArgs = (0, exports.IntegrationsServiceApiFetchParamCreator)(configuration).integrationsServiceDeleteQuickSightCache(options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Retrieves all visible dashboards from quicksight
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetAdminDashboards: function (options) {
            var localVarFetchArgs = (0, exports.IntegrationsServiceApiFetchParamCreator)(configuration).integrationsServiceGetAdminDashboards(options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns an embedded url which renders a dashboard
         * @param {string} id the dashboard_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboardURL: function (id, options) {
            var localVarFetchArgs = (0, exports.IntegrationsServiceApiFetchParamCreator)(configuration).integrationsServiceGetDashboardURL(id, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Retrives the dashboards the connected user can access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboards: function (options) {
            var localVarFetchArgs = (0, exports.IntegrationsServiceApiFetchParamCreator)(configuration).integrationsServiceGetDashboards(options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Retrieves the dashboards visible for one given user
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboardsUser: function (userId, options) {
            var localVarFetchArgs = (0, exports.IntegrationsServiceApiFetchParamCreator)(configuration).integrationsServiceGetDashboardsUser(userId, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Checks service is up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetHealth: function (options) {
            var localVarFetchArgs = (0, exports.IntegrationsServiceApiFetchParamCreator)(configuration).integrationsServiceGetHealth(options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Grants and revokes permissions to a dashboard for a given user
         * @param {IntegrationsPostDashboardInput} body
         * @param {string} userId UserID is the the user identifier en reside
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServicePostDashboard: function (body, userId, options) {
            var localVarFetchArgs = (0, exports.IntegrationsServiceApiFetchParamCreator)(configuration).integrationsServicePostDashboard(body, userId, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.IntegrationsServiceApiFp = IntegrationsServiceApiFp;
/**
 * IntegrationsServiceApi - factory interface
 * @export
 */
var IntegrationsServiceApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Removes all QuickSight cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceDeleteQuickSightCache: function (options) {
            return (0, exports.IntegrationsServiceApiFp)(configuration).integrationsServiceDeleteQuickSightCache(options)(fetch, basePath);
        },
        /**
         *
         * @summary Retrieves all visible dashboards from quicksight
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetAdminDashboards: function (options) {
            return (0, exports.IntegrationsServiceApiFp)(configuration).integrationsServiceGetAdminDashboards(options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns an embedded url which renders a dashboard
         * @param {string} id the dashboard_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboardURL: function (id, options) {
            return (0, exports.IntegrationsServiceApiFp)(configuration).integrationsServiceGetDashboardURL(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary Retrives the dashboards the connected user can access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboards: function (options) {
            return (0, exports.IntegrationsServiceApiFp)(configuration).integrationsServiceGetDashboards(options)(fetch, basePath);
        },
        /**
         *
         * @summary Retrieves the dashboards visible for one given user
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetDashboardsUser: function (userId, options) {
            return (0, exports.IntegrationsServiceApiFp)(configuration).integrationsServiceGetDashboardsUser(userId, options)(fetch, basePath);
        },
        /**
         *
         * @summary Checks service is up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServiceGetHealth: function (options) {
            return (0, exports.IntegrationsServiceApiFp)(configuration).integrationsServiceGetHealth(options)(fetch, basePath);
        },
        /**
         *
         * @summary Grants and revokes permissions to a dashboard for a given user
         * @param {IntegrationsPostDashboardInput} body
         * @param {string} userId UserID is the the user identifier en reside
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsServicePostDashboard: function (body, userId, options) {
            return (0, exports.IntegrationsServiceApiFp)(configuration).integrationsServicePostDashboard(body, userId, options)(fetch, basePath);
        },
    };
};
exports.IntegrationsServiceApiFactory = IntegrationsServiceApiFactory;
/**
 * IntegrationsServiceApi - object-oriented interface
 * @export
 * @class IntegrationsServiceApi
 * @extends {BaseAPI}
 */
var IntegrationsServiceApi = /** @class */ (function (_super) {
    __extends(IntegrationsServiceApi, _super);
    function IntegrationsServiceApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Removes all QuickSight cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsServiceApi
     */
    IntegrationsServiceApi.prototype.integrationsServiceDeleteQuickSightCache = function (options) {
        return (0, exports.IntegrationsServiceApiFp)(this.configuration).integrationsServiceDeleteQuickSightCache(options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Retrieves all visible dashboards from quicksight
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsServiceApi
     */
    IntegrationsServiceApi.prototype.integrationsServiceGetAdminDashboards = function (options) {
        return (0, exports.IntegrationsServiceApiFp)(this.configuration).integrationsServiceGetAdminDashboards(options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns an embedded url which renders a dashboard
     * @param {string} id the dashboard_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsServiceApi
     */
    IntegrationsServiceApi.prototype.integrationsServiceGetDashboardURL = function (id, options) {
        return (0, exports.IntegrationsServiceApiFp)(this.configuration).integrationsServiceGetDashboardURL(id, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Retrives the dashboards the connected user can access to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsServiceApi
     */
    IntegrationsServiceApi.prototype.integrationsServiceGetDashboards = function (options) {
        return (0, exports.IntegrationsServiceApiFp)(this.configuration).integrationsServiceGetDashboards(options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Retrieves the dashboards visible for one given user
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsServiceApi
     */
    IntegrationsServiceApi.prototype.integrationsServiceGetDashboardsUser = function (userId, options) {
        return (0, exports.IntegrationsServiceApiFp)(this.configuration).integrationsServiceGetDashboardsUser(userId, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Checks service is up
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsServiceApi
     */
    IntegrationsServiceApi.prototype.integrationsServiceGetHealth = function (options) {
        return (0, exports.IntegrationsServiceApiFp)(this.configuration).integrationsServiceGetHealth(options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Grants and revokes permissions to a dashboard for a given user
     * @param {IntegrationsPostDashboardInput} body
     * @param {string} userId UserID is the the user identifier en reside
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsServiceApi
     */
    IntegrationsServiceApi.prototype.integrationsServicePostDashboard = function (body, userId, options) {
        return (0, exports.IntegrationsServiceApiFp)(this.configuration).integrationsServicePostDashboard(body, userId, options)(this.fetch, this.basePath);
    };
    return IntegrationsServiceApi;
}(BaseAPI));
exports.IntegrationsServiceApi = IntegrationsServiceApi;
