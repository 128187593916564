"use strict";
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Reside Document
 * Reside document micro-service
 *
 * OpenAPI spec version: 0.0.1
 * Contact: peter.sokolik@thinkcreatix.com
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentControllerApi = exports.DocumentControllerApiFactory = exports.DocumentControllerApiFp = exports.DocumentControllerApiFetchParamCreator = exports.QuestionReference = exports.Pcc = exports.PartialPdfReference = exports.ChecklistItem = exports.Approval = exports.AnswerOfobject = exports.AdmissionNote = exports.AdmissionFileReference = exports.Admission = exports.RequiredError = exports.BaseAPI = exports.COLLECTION_FORMATS = void 0;
var url = __importStar(require("url"));
var portableFetch = __importStar(require("portable-fetch"));
var BASE_PATH = "//localhost:8083/".replace(/\/+$/, "");
/**
 *
 * @export
 */
exports.COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration, basePath, fetch) {
        if (basePath === void 0) { basePath = BASE_PATH; }
        if (fetch === void 0) { fetch = portableFetch; }
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
    return BaseAPI;
}());
exports.BaseAPI = BaseAPI;
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        return _this;
    }
    return RequiredError;
}(Error));
exports.RequiredError = RequiredError;
/**
 * @export
 * @namespace Admission
 */
var Admission;
(function (Admission) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        StatusEnum["CANCELLED"] = "CANCELLED";
        StatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
        StatusEnum["SIGNED"] = "SIGNED";
        StatusEnum["APPROVED"] = "APPROVED";
        StatusEnum["ARCHIVED"] = "ARCHIVED";
        StatusEnum["INCOMPLETE"] = "INCOMPLETE";
    })(StatusEnum = Admission.StatusEnum || (Admission.StatusEnum = {}));
})(Admission = exports.Admission || (exports.Admission = {}));
/**
 * @export
 * @namespace AdmissionFileReference
 */
var AdmissionFileReference;
(function (AdmissionFileReference) {
    /**
     * @export
     * @enum {string}
     */
    var PccUploadStatusEnum;
    (function (PccUploadStatusEnum) {
        PccUploadStatusEnum["SUCCESS"] = "SUCCESS";
        PccUploadStatusEnum["PENDING"] = "PENDING";
        PccUploadStatusEnum["ERROR"] = "ERROR";
    })(PccUploadStatusEnum = AdmissionFileReference.PccUploadStatusEnum || (AdmissionFileReference.PccUploadStatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["ADMISSION"] = "ADMISSION";
        TypeEnum["PHOTO"] = "PHOTO";
        TypeEnum["TEMPLATE"] = "TEMPLATE";
        TypeEnum["PICTURE"] = "PICTURE";
        TypeEnum["VARIABLE"] = "VARIABLE";
        TypeEnum["ANSWER"] = "ANSWER";
        TypeEnum["PROTECTED"] = "PROTECTED";
        TypeEnum["USER_IMPORT"] = "USER_IMPORT";
        TypeEnum["USER_EXPORT"] = "USER_EXPORT";
    })(TypeEnum = AdmissionFileReference.TypeEnum || (AdmissionFileReference.TypeEnum = {}));
})(AdmissionFileReference = exports.AdmissionFileReference || (exports.AdmissionFileReference = {}));
/**
 * @export
 * @namespace AdmissionNote
 */
var AdmissionNote;
(function (AdmissionNote) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["NEW"] = "NEW";
        StatusEnum["RESOLVED"] = "RESOLVED";
        StatusEnum["CANCELLED"] = "CANCELLED";
    })(StatusEnum = AdmissionNote.StatusEnum || (AdmissionNote.StatusEnum = {}));
})(AdmissionNote = exports.AdmissionNote || (exports.AdmissionNote = {}));
/**
 * @export
 * @namespace AnswerOfobject
 */
var AnswerOfobject;
(function (AnswerOfobject) {
    /**
     * @export
     * @enum {string}
     */
    var QuestionTypeEnum;
    (function (QuestionTypeEnum) {
        QuestionTypeEnum["TEXT"] = "TEXT";
        QuestionTypeEnum["DATE"] = "DATE";
        QuestionTypeEnum["DATETIME"] = "DATETIME";
        QuestionTypeEnum["CHECKBOX"] = "CHECKBOX";
        QuestionTypeEnum["RADIO"] = "RADIO";
        QuestionTypeEnum["SELECT"] = "SELECT";
        QuestionTypeEnum["STATE_SELECT"] = "STATE_SELECT";
        QuestionTypeEnum["SIGNATURE"] = "SIGNATURE";
        QuestionTypeEnum["IMAGE"] = "IMAGE";
        QuestionTypeEnum["TEXTAREA"] = "TEXTAREA";
        QuestionTypeEnum["OBJECT"] = "OBJECT";
        QuestionTypeEnum["LIST"] = "LIST";
        QuestionTypeEnum["FILE"] = "FILE";
        QuestionTypeEnum["CURRENCY"] = "CURRENCY";
        QuestionTypeEnum["TIMEZONE_SELECT"] = "TIMEZONE_SELECT";
    })(QuestionTypeEnum = AnswerOfobject.QuestionTypeEnum || (AnswerOfobject.QuestionTypeEnum = {}));
})(AnswerOfobject = exports.AnswerOfobject || (exports.AnswerOfobject = {}));
/**
 * @export
 * @namespace Approval
 */
var Approval;
(function (Approval) {
    /**
     * @export
     * @enum {string}
     */
    var ApproverTitleEnum;
    (function (ApproverTitleEnum) {
        ApproverTitleEnum["ADMISSIONS_FINANCIAL_SPECIALIST"] = "ADMISSIONS_FINANCIAL_SPECIALIST";
        ApproverTitleEnum["ADMISSIONS_DIRECTOR"] = "ADMISSIONS_DIRECTOR";
        ApproverTitleEnum["FINANCIAL_COORDINATOR"] = "FINANCIAL_COORDINATOR";
        ApproverTitleEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
        ApproverTitleEnum["SOCIAL_SERVICES_DIRECTOR"] = "SOCIAL_SERVICES_DIRECTOR";
        ApproverTitleEnum["LIAISON"] = "LIAISON";
        ApproverTitleEnum["BUSINESS_OFFICE_MANAGER"] = "BUSINESS_OFFICE_MANAGER";
        ApproverTitleEnum["EXECUTIVE_DIRECTOR"] = "EXECUTIVE_DIRECTOR";
        ApproverTitleEnum["LICENSEE"] = "LICENSEE";
        ApproverTitleEnum["GENERAL_MANAGER"] = "GENERAL_MANAGER";
        ApproverTitleEnum["FACILITY_REPRESENTATIVE"] = "FACILITY_REPRESENTATIVE";
    })(ApproverTitleEnum = Approval.ApproverTitleEnum || (Approval.ApproverTitleEnum = {}));
})(Approval = exports.Approval || (exports.Approval = {}));
/**
 * @export
 * @namespace ChecklistItem
 */
var ChecklistItem;
(function (ChecklistItem) {
    /**
     * @export
     * @enum {string}
     */
    var CheckedStatusEnum;
    (function (CheckedStatusEnum) {
        CheckedStatusEnum["OPEN"] = "OPEN";
        CheckedStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
        CheckedStatusEnum["COMPLETED"] = "COMPLETED";
        CheckedStatusEnum["NOT_APPLICABLE"] = "NOT_APPLICABLE";
    })(CheckedStatusEnum = ChecklistItem.CheckedStatusEnum || (ChecklistItem.CheckedStatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var MandatoryForStatusesEnum;
    (function (MandatoryForStatusesEnum) {
        MandatoryForStatusesEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        MandatoryForStatusesEnum["CANCELLED"] = "CANCELLED";
        MandatoryForStatusesEnum["IN_PROGRESS"] = "IN_PROGRESS";
        MandatoryForStatusesEnum["SIGNED"] = "SIGNED";
        MandatoryForStatusesEnum["APPROVED"] = "APPROVED";
        MandatoryForStatusesEnum["ARCHIVED"] = "ARCHIVED";
        MandatoryForStatusesEnum["INCOMPLETE"] = "INCOMPLETE";
    })(MandatoryForStatusesEnum = ChecklistItem.MandatoryForStatusesEnum || (ChecklistItem.MandatoryForStatusesEnum = {}));
})(ChecklistItem = exports.ChecklistItem || (exports.ChecklistItem = {}));
/**
 * @export
 * @namespace PartialPdfReference
 */
var PartialPdfReference;
(function (PartialPdfReference) {
    /**
     * @export
     * @enum {string}
     */
    var PccUploadStatusEnum;
    (function (PccUploadStatusEnum) {
        PccUploadStatusEnum["SUCCESS"] = "SUCCESS";
        PccUploadStatusEnum["PENDING"] = "PENDING";
        PccUploadStatusEnum["ERROR"] = "ERROR";
    })(PccUploadStatusEnum = PartialPdfReference.PccUploadStatusEnum || (PartialPdfReference.PccUploadStatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["ADMISSION"] = "ADMISSION";
        TypeEnum["PHOTO"] = "PHOTO";
        TypeEnum["TEMPLATE"] = "TEMPLATE";
        TypeEnum["PICTURE"] = "PICTURE";
        TypeEnum["VARIABLE"] = "VARIABLE";
        TypeEnum["ANSWER"] = "ANSWER";
        TypeEnum["PROTECTED"] = "PROTECTED";
        TypeEnum["USER_IMPORT"] = "USER_IMPORT";
        TypeEnum["USER_EXPORT"] = "USER_EXPORT";
    })(TypeEnum = PartialPdfReference.TypeEnum || (PartialPdfReference.TypeEnum = {}));
})(PartialPdfReference = exports.PartialPdfReference || (exports.PartialPdfReference = {}));
/**
 * @export
 * @namespace Pcc
 */
var Pcc;
(function (Pcc) {
    /**
     * @export
     * @enum {string}
     */
    var RelatedAdmissionStatusEnum;
    (function (RelatedAdmissionStatusEnum) {
        RelatedAdmissionStatusEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        RelatedAdmissionStatusEnum["CANCELLED"] = "CANCELLED";
        RelatedAdmissionStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
        RelatedAdmissionStatusEnum["SIGNED"] = "SIGNED";
        RelatedAdmissionStatusEnum["APPROVED"] = "APPROVED";
        RelatedAdmissionStatusEnum["ARCHIVED"] = "ARCHIVED";
        RelatedAdmissionStatusEnum["INCOMPLETE"] = "INCOMPLETE";
    })(RelatedAdmissionStatusEnum = Pcc.RelatedAdmissionStatusEnum || (Pcc.RelatedAdmissionStatusEnum = {}));
})(Pcc = exports.Pcc || (exports.Pcc = {}));
/**
 * @export
 * @namespace QuestionReference
 */
var QuestionReference;
(function (QuestionReference) {
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["TEXT"] = "TEXT";
        TypeEnum["DATE"] = "DATE";
        TypeEnum["DATETIME"] = "DATETIME";
        TypeEnum["CHECKBOX"] = "CHECKBOX";
        TypeEnum["RADIO"] = "RADIO";
        TypeEnum["SELECT"] = "SELECT";
        TypeEnum["STATE_SELECT"] = "STATE_SELECT";
        TypeEnum["SIGNATURE"] = "SIGNATURE";
        TypeEnum["IMAGE"] = "IMAGE";
        TypeEnum["TEXTAREA"] = "TEXTAREA";
        TypeEnum["OBJECT"] = "OBJECT";
        TypeEnum["LIST"] = "LIST";
        TypeEnum["FILE"] = "FILE";
        TypeEnum["CURRENCY"] = "CURRENCY";
        TypeEnum["TIMEZONE_SELECT"] = "TIMEZONE_SELECT";
    })(TypeEnum = QuestionReference.TypeEnum || (QuestionReference.TypeEnum = {}));
})(QuestionReference = exports.QuestionReference || (exports.QuestionReference = {}));
/**
 * DocumentControllerApi - fetch parameter creator
 * @export
 */
var DocumentControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Generate PDF based on given admission data and save the generated PDF into a persistent storage
         * @param {Admission} body admission
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAndSavePdfUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling generateAndSavePdfUsingPOST.');
            }
            var localVarPath = "/api/v3/documents/generate/save";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("Admission" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Generate basic rates PDF based on given admission data
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBasicRatesPdfUsingGET: function (accessToken, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken', 'Required parameter accessToken was null or undefined when calling generateBasicRatesPdfUsingGET.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling generateBasicRatesPdfUsingGET.');
            }
            var localVarPath = "/api/v3/documents/{admissionId}/generate/rates"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Generate PDF based on given admission data
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} [body] gaugeToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePdfUsingGET: function (accessToken, admissionId, body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken', 'Required parameter accessToken was null or undefined when calling generatePdfUsingGET.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling generatePdfUsingGET.');
            }
            var localVarPath = "/api/v3/documents/{admissionId}/generate"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = '*/*';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Generate PDF based on given admission data
         * @param {Admission} body admission
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePdfUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling generatePdfUsingPOST.');
            }
            var localVarPath = "/api/v3/documents/generate";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("Admission" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityPropertiesUsingGET: function (admissionTemplateId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionTemplateId' is not null or undefined
            if (admissionTemplateId === null || admissionTemplateId === undefined) {
                throw new RequiredError('admissionTemplateId', 'Required parameter admissionTemplateId was null or undefined when calling getFacilityPropertiesUsingGET.');
            }
            var localVarPath = "/api/v3/documents/facility-properties/{admissionTemplateId}"
                .replace("{".concat("admissionTemplateId", "}"), encodeURIComponent(String(admissionTemplateId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get template for facility settings
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitySettingsTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionTemplateId' is not null or undefined
            if (admissionTemplateId === null || admissionTemplateId === undefined) {
                throw new RequiredError('admissionTemplateId', 'Required parameter admissionTemplateId was null or undefined when calling getFacilitySettingsTemplateUsingGET.');
            }
            var localVarPath = "/api/v3/documents/facility-settings-template/{admissionTemplateId}"
                .replace("{".concat("admissionTemplateId", "}"), encodeURIComponent(String(admissionTemplateId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get latest PDF template for given template name
         * @param {string} facilityId facilityId
         * @param {string} templateName templateName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPdfTemplateUsingGET: function (facilityId, templateName, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getLatestPdfTemplateUsingGET.');
            }
            // verify required parameter 'templateName' is not null or undefined
            if (templateName === null || templateName === undefined) {
                throw new RequiredError('templateName', 'Required parameter templateName was null or undefined when calling getLatestPdfTemplateUsingGET.');
            }
            var localVarPath = "/api/v3/documents/template/{facilityId}/{templateName}/pdf/latest"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)))
                .replace("{".concat("templateName", "}"), encodeURIComponent(String(templateName)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get latest admission document for facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestTemplateVersionUsingGET: function (facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getLatestTemplateVersionUsingGET.');
            }
            var localVarPath = "/api/v3/documents/facilities/{facilityId}/version"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Generate partial PDF template for given group key. Group key is made by replacing all non-word chars of group id with underscore.
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} groupKey groupKey
         * @param {string} [body] gaugeToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartialPdfUsingGET: function (accessToken, admissionId, groupKey, body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accessToken' is not null or undefined
            if (accessToken === null || accessToken === undefined) {
                throw new RequiredError('accessToken', 'Required parameter accessToken was null or undefined when calling getPartialPdfUsingGET.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling getPartialPdfUsingGET.');
            }
            // verify required parameter 'groupKey' is not null or undefined
            if (groupKey === null || groupKey === undefined) {
                throw new RequiredError('groupKey', 'Required parameter groupKey was null or undefined when calling getPartialPdfUsingGET.');
            }
            var localVarPath = "/api/v3/documents/{admissionId}/generate/{groupKey}"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)))
                .replace("{".concat("groupKey", "}"), encodeURIComponent(String(groupKey)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = '*/*';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get template for preflight
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreflightTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionTemplateId' is not null or undefined
            if (admissionTemplateId === null || admissionTemplateId === undefined) {
                throw new RequiredError('admissionTemplateId', 'Required parameter admissionTemplateId was null or undefined when calling getPreflightTemplateUsingGET.');
            }
            var localVarPath = "/api/v3/documents/preflight-template/{admissionTemplateId}"
                .replace("{".concat("admissionTemplateId", "}"), encodeURIComponent(String(admissionTemplateId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get template for resident experience prelude
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentExperiencePreludeTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionTemplateId' is not null or undefined
            if (admissionTemplateId === null || admissionTemplateId === undefined) {
                throw new RequiredError('admissionTemplateId', 'Required parameter admissionTemplateId was null or undefined when calling getResidentExperiencePreludeTemplateUsingGET.');
            }
            var localVarPath = "/api/v3/documents/resident-experience-prelude-template/{admissionTemplateId}"
                .replace("{".concat("admissionTemplateId", "}"), encodeURIComponent(String(admissionTemplateId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get template for admission version
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionTemplateId' is not null or undefined
            if (admissionTemplateId === null || admissionTemplateId === undefined) {
                throw new RequiredError('admissionTemplateId', 'Required parameter admissionTemplateId was null or undefined when calling getTemplateUsingGET.');
            }
            var localVarPath = "/api/v3/documents/template/{admissionTemplateId}"
                .replace("{".concat("admissionTemplateId", "}"), encodeURIComponent(String(admissionTemplateId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get admission document for facility by version id
         * @param {string} facilityId facilityId
         * @param {string} versionId versionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateVersionUsingGET: function (facilityId, versionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getTemplateVersionUsingGET.');
            }
            // verify required parameter 'versionId' is not null or undefined
            if (versionId === null || versionId === undefined) {
                throw new RequiredError('versionId', 'Required parameter versionId was null or undefined when calling getTemplateVersionUsingGET.');
            }
            var localVarPath = "/api/v3/documents/facilities/{facilityId}/version/{versionId}"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)))
                .replace("{".concat("versionId", "}"), encodeURIComponent(String(versionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get templates list
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplatesNamesUsingGET: function (authorization, options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/api/v3/documents/templates";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary synchronizeFacility
         * @param {string} facilityId facilityId
         * @param {string} templateName templateName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizeFacilityUsingGET: function (facilityId, templateName, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling synchronizeFacilityUsingGET.');
            }
            // verify required parameter 'templateName' is not null or undefined
            if (templateName === null || templateName === undefined) {
                throw new RequiredError('templateName', 'Required parameter templateName was null or undefined when calling synchronizeFacilityUsingGET.');
            }
            var localVarPath = "/api/v3/documents/{facilityId}/{templateName}/synchronize"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)))
                .replace("{".concat("templateName", "}"), encodeURIComponent(String(templateName)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.DocumentControllerApiFetchParamCreator = DocumentControllerApiFetchParamCreator;
/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
var DocumentControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Generate PDF based on given admission data and save the generated PDF into a persistent storage
         * @param {Admission} body admission
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAndSavePdfUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).generateAndSavePdfUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Generate basic rates PDF based on given admission data
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBasicRatesPdfUsingGET: function (accessToken, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).generateBasicRatesPdfUsingGET(accessToken, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Generate PDF based on given admission data
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} [body] gaugeToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePdfUsingGET: function (accessToken, admissionId, body, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).generatePdfUsingGET(accessToken, admissionId, body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Generate PDF based on given admission data
         * @param {Admission} body admission
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePdfUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).generatePdfUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityPropertiesUsingGET: function (admissionTemplateId, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getFacilityPropertiesUsingGET(admissionTemplateId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get template for facility settings
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitySettingsTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getFacilitySettingsTemplateUsingGET(admissionTemplateId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get latest PDF template for given template name
         * @param {string} facilityId facilityId
         * @param {string} templateName templateName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPdfTemplateUsingGET: function (facilityId, templateName, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getLatestPdfTemplateUsingGET(facilityId, templateName, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get latest admission document for facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestTemplateVersionUsingGET: function (facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getLatestTemplateVersionUsingGET(facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Generate partial PDF template for given group key. Group key is made by replacing all non-word chars of group id with underscore.
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} groupKey groupKey
         * @param {string} [body] gaugeToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartialPdfUsingGET: function (accessToken, admissionId, groupKey, body, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getPartialPdfUsingGET(accessToken, admissionId, groupKey, body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get template for preflight
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreflightTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getPreflightTemplateUsingGET(admissionTemplateId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get template for resident experience prelude
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentExperiencePreludeTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getResidentExperiencePreludeTemplateUsingGET(admissionTemplateId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get template for admission version
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getTemplateUsingGET(admissionTemplateId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get admission document for facility by version id
         * @param {string} facilityId facilityId
         * @param {string} versionId versionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateVersionUsingGET: function (facilityId, versionId, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getTemplateVersionUsingGET(facilityId, versionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get templates list
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplatesNamesUsingGET: function (authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).getTemplatesNamesUsingGET(authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary synchronizeFacility
         * @param {string} facilityId facilityId
         * @param {string} templateName templateName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizeFacilityUsingGET: function (facilityId, templateName, authorization, options) {
            var localVarFetchArgs = (0, exports.DocumentControllerApiFetchParamCreator)(configuration).synchronizeFacilityUsingGET(facilityId, templateName, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.DocumentControllerApiFp = DocumentControllerApiFp;
/**
 * DocumentControllerApi - factory interface
 * @export
 */
var DocumentControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Generate PDF based on given admission data and save the generated PDF into a persistent storage
         * @param {Admission} body admission
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAndSavePdfUsingPOST: function (body, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).generateAndSavePdfUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Generate basic rates PDF based on given admission data
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBasicRatesPdfUsingGET: function (accessToken, admissionId, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).generateBasicRatesPdfUsingGET(accessToken, admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Generate PDF based on given admission data
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} [body] gaugeToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePdfUsingGET: function (accessToken, admissionId, body, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).generatePdfUsingGET(accessToken, admissionId, body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Generate PDF based on given admission data
         * @param {Admission} body admission
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePdfUsingPOST: function (body, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).generatePdfUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilityPropertiesUsingGET: function (admissionTemplateId, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getFacilityPropertiesUsingGET(admissionTemplateId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get template for facility settings
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacilitySettingsTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getFacilitySettingsTemplateUsingGET(admissionTemplateId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get latest PDF template for given template name
         * @param {string} facilityId facilityId
         * @param {string} templateName templateName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPdfTemplateUsingGET: function (facilityId, templateName, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getLatestPdfTemplateUsingGET(facilityId, templateName, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get latest admission document for facility
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestTemplateVersionUsingGET: function (facilityId, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getLatestTemplateVersionUsingGET(facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Generate partial PDF template for given group key. Group key is made by replacing all non-word chars of group id with underscore.
         * @param {string} accessToken accessToken
         * @param {string} admissionId admissionId
         * @param {string} groupKey groupKey
         * @param {string} [body] gaugeToken
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartialPdfUsingGET: function (accessToken, admissionId, groupKey, body, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getPartialPdfUsingGET(accessToken, admissionId, groupKey, body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get template for preflight
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreflightTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getPreflightTemplateUsingGET(admissionTemplateId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get template for resident experience prelude
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentExperiencePreludeTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getResidentExperiencePreludeTemplateUsingGET(admissionTemplateId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get template for admission version
         * @param {string} admissionTemplateId identifier of admission&#x27;s template
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateUsingGET: function (admissionTemplateId, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getTemplateUsingGET(admissionTemplateId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get admission document for facility by version id
         * @param {string} facilityId facilityId
         * @param {string} versionId versionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateVersionUsingGET: function (facilityId, versionId, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getTemplateVersionUsingGET(facilityId, versionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get templates list
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplatesNamesUsingGET: function (authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).getTemplatesNamesUsingGET(authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary synchronizeFacility
         * @param {string} facilityId facilityId
         * @param {string} templateName templateName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizeFacilityUsingGET: function (facilityId, templateName, authorization, options) {
            return (0, exports.DocumentControllerApiFp)(configuration).synchronizeFacilityUsingGET(facilityId, templateName, authorization, options)(fetch, basePath);
        },
    };
};
exports.DocumentControllerApiFactory = DocumentControllerApiFactory;
/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
var DocumentControllerApi = /** @class */ (function (_super) {
    __extends(DocumentControllerApi, _super);
    function DocumentControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Generate PDF based on given admission data and save the generated PDF into a persistent storage
     * @param {Admission} body admission
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.generateAndSavePdfUsingPOST = function (body, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).generateAndSavePdfUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Generate basic rates PDF based on given admission data
     * @param {string} accessToken accessToken
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.generateBasicRatesPdfUsingGET = function (accessToken, admissionId, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).generateBasicRatesPdfUsingGET(accessToken, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Generate PDF based on given admission data
     * @param {string} accessToken accessToken
     * @param {string} admissionId admissionId
     * @param {string} [body] gaugeToken
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.generatePdfUsingGET = function (accessToken, admissionId, body, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).generatePdfUsingGET(accessToken, admissionId, body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Generate PDF based on given admission data
     * @param {Admission} body admission
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.generatePdfUsingPOST = function (body, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).generatePdfUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @param {string} admissionTemplateId identifier of admission&#x27;s template
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getFacilityPropertiesUsingGET = function (admissionTemplateId, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getFacilityPropertiesUsingGET(admissionTemplateId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get template for facility settings
     * @param {string} admissionTemplateId identifier of admission&#x27;s template
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getFacilitySettingsTemplateUsingGET = function (admissionTemplateId, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getFacilitySettingsTemplateUsingGET(admissionTemplateId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get latest PDF template for given template name
     * @param {string} facilityId facilityId
     * @param {string} templateName templateName
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getLatestPdfTemplateUsingGET = function (facilityId, templateName, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getLatestPdfTemplateUsingGET(facilityId, templateName, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get latest admission document for facility
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getLatestTemplateVersionUsingGET = function (facilityId, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getLatestTemplateVersionUsingGET(facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Generate partial PDF template for given group key. Group key is made by replacing all non-word chars of group id with underscore.
     * @param {string} accessToken accessToken
     * @param {string} admissionId admissionId
     * @param {string} groupKey groupKey
     * @param {string} [body] gaugeToken
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getPartialPdfUsingGET = function (accessToken, admissionId, groupKey, body, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getPartialPdfUsingGET(accessToken, admissionId, groupKey, body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get template for preflight
     * @param {string} admissionTemplateId identifier of admission&#x27;s template
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getPreflightTemplateUsingGET = function (admissionTemplateId, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getPreflightTemplateUsingGET(admissionTemplateId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get template for resident experience prelude
     * @param {string} admissionTemplateId identifier of admission&#x27;s template
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getResidentExperiencePreludeTemplateUsingGET = function (admissionTemplateId, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getResidentExperiencePreludeTemplateUsingGET(admissionTemplateId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get template for admission version
     * @param {string} admissionTemplateId identifier of admission&#x27;s template
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getTemplateUsingGET = function (admissionTemplateId, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getTemplateUsingGET(admissionTemplateId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get admission document for facility by version id
     * @param {string} facilityId facilityId
     * @param {string} versionId versionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getTemplateVersionUsingGET = function (facilityId, versionId, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getTemplateVersionUsingGET(facilityId, versionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get templates list
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.getTemplatesNamesUsingGET = function (authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).getTemplatesNamesUsingGET(authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary synchronizeFacility
     * @param {string} facilityId facilityId
     * @param {string} templateName templateName
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    DocumentControllerApi.prototype.synchronizeFacilityUsingGET = function (facilityId, templateName, authorization, options) {
        return (0, exports.DocumentControllerApiFp)(this.configuration).synchronizeFacilityUsingGET(facilityId, templateName, authorization, options)(this.fetch, this.basePath);
    };
    return DocumentControllerApi;
}(BaseAPI));
exports.DocumentControllerApi = DocumentControllerApi;
