import {useQuery} from "react-query";

import {getAuthorizationHeader} from "../../../models/admin-session/selectors";
import {featureFlagsApi} from "../../../services/FeatureFlagsService";

export const useFetchAllOrganizationFeatureFlags = () =>
  useQuery(
    "useFetchAllOrganizationFeatureFlags",
    async () => {
      const {data} =
        await featureFlagsApi.getOrganizationFeatureFlagsTypesUsingGET(
          getAuthorizationHeader(),
        );
      return data;
    },
    // Always keep data in cache to avoid re-fetching this static data
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
